import React, { useState, useEffect } from 'react';
import { Dropdown } from 'react-bootstrap';
import { FormattedMessage, useIntl } from 'react-intl';
import QuestionBanksTreeView from './tree-view/QuestionBanksTreeView';
import AddQuestionBanks from '../../components/Questionbanks/AddQuestionBanks';
import SearchBox from '../../components/common/SearchBox/SearchBox';
import Advancedsearch from './Advancedsearch';
import { toastify } from '../../components/common/Toastify';
import { getAllDisciplines, getUserDisciplines } from '../../services/discipline.service';
import { getUserBooks } from '../../services/book.service';
import { useAppContext } from '../../context/AppContext';

import './QuestionBanks.css';

const AdvancedSearchLink = React.forwardRef(({ children, onClick }, ref) => (
  <a
    href=""
    ref={ref}
    className="button-link btn btn-link"
    onClick={e => {
      e.preventDefault();
      onClick(e);
    }}
  >
    {children}
  </a>
));

const CustomMenu = React.forwardRef(
  ({ style, className, 'aria-labelledby': labeledBy, options, closeDropdown, onAdvancedSearch }, ref) => {
    return (
      <div ref={ref} style={style} className={className} aria-labelledby={labeledBy}>
        <Advancedsearch options={options} closeDropdown={closeDropdown} onAdvancedSearch={onAdvancedSearch} />
      </div>
    );
  }
);

const QuestionBanks = () => {
  const intl = useIntl();
  const {
    disciplinesData: { userDisciplines, userBooks },
    questionBanksData: { advancedSearchSelection, selectedBookIds, simpleSearchText },
    dispatchEvent,
  } = useAppContext();
  const [showDropdown, setShowDropdown] = useState(false);

  // Load user disciplines and books when the component mounts
  useEffect(() => {
    document.title = 'Question Banks';
    loadUserResources();
  }, []);

  /**
   * Loads the user's selected disciplines and books from the server and updates the state with the fetched data.
   */
  const loadUserResources = async () => {
    dispatchEvent('SHOW_LOADER');
    try {
      const [allDisciplines, disciplines, books] = await Promise.all([
        getAllDisciplines(),
        getUserDisciplines(),
        getUserBooks(),
      ]);

      // Clear Advanced search on changing disciplines data
      // setAdvancedSearchSelection([]);
      const disciplinesData = {
        allDisciplines,
        userBooks: books,
        selectedBooks: books,
      };

      if (JSON.stringify(userDisciplines) !== JSON.stringify(disciplines)) {
        disciplinesData['userDisciplines'] = disciplines;
      }

      dispatchEvent('UPDATE_DISCIPLINES_DATA', disciplinesData);
    } catch (error) {
      toastify.showErrorToast(error);
    } finally {
      dispatchEvent('HIDE_LOADER');
    }
  };

  const handleSearch = searchText => {
    dispatchEvent('UPDATE_SIMPLE_SEARCH_TEXT', searchText);
  };

  const clearSimpleSearch = () => {
    dispatchEvent('CLEAR_SIMPLE_SEARCH_TEXT');
  };

  const toggleDropdown = () => {
    if (selectedBookIds.length) {
      setShowDropdown(!showDropdown);
    } else {
      toastify.showWarningToast(intl.formatMessage({ id: 'warning.UserMust.SelectBooks' }));
    }
  };

  const closeDropdown = () => {
    setShowDropdown(false);
  };

  const handleAdvancedSearchChange = selection => {
    dispatchEvent('UPDATE_ADVANCED_SEARCH_SELECTION', selection);
    closeDropdown();
  };

  const handleClearAdvancedSearch = e => {
    e.preventDefault();
    dispatchEvent('CLEAR_ADVANCED_SEARCH_SELECTION');
  };

  return (
    <div className="p-2 flex-column">
      <div className="questionBanksearchcontainer">
        <div className="col-lg-7 ml-8 mr-2">
          <SearchBox
            searchText={simpleSearchText}
            placeholder="Search selected banks"
            onSearch={handleSearch}
            onClear={clearSimpleSearch}
          />
        </div>
        <Dropdown className="" show={showDropdown} onToggle={toggleDropdown}>
          <Dropdown.Toggle
            align={{ lg: 'start' }}
            variant="primary"
            id="dropdown-custom-components"
            as={AdvancedSearchLink}
            onClick={toggleDropdown}
          >
            <FormattedMessage id="message.advancedsearch" />
          </Dropdown.Toggle>

          <Dropdown.Menu
            className="mt-1"
            as={CustomMenu}
            options={advancedSearchSelection}
            closeDropdown={closeDropdown}
            onAdvancedSearch={handleAdvancedSearchChange}
          />
        </Dropdown>
        <div className="">
          <a
            href=""
            className={`button-link btn btn-link ${!advancedSearchSelection.length ? 'disabled' : ''}`}
            onClick={e => handleClearAdvancedSearch(e)}
            disabled={!advancedSearchSelection.length}
            aria-label="clear search link disabled"
          >
            <FormattedMessage id="message.clearSearch" />
          </a>
        </div>
      </div>

      <div className="questionBank">
        <AddQuestionBanks reloadDisciplines={loadUserResources} />
      </div>
      {userDisciplines?.length > 0 && (
        <div className="discipline-books">
          <QuestionBanksTreeView disciplines={userDisciplines} books={userBooks} />
        </div>
      )}
    </div>
  );
};

export default QuestionBanks;
