import httpInterceptor from '../httpHelper/httpHelper';
import { extractErrorMessage } from '../utils/common';

const url = process.env.REACT_APP_API_URL;

export const fetchAllNodesUnderBook = async (bookId, queryParams = {}) => {
  const queryString = new URLSearchParams(queryParams).toString();
  const requestUrl = `${url}/books/${bookId}/nodes${queryString ? `?${queryString}` : ''}`;

  try {
    const response = await httpInterceptor.get(requestUrl);
    return response?.data;
  } catch (error) {
    throw extractErrorMessage(error);
  }
};

export const getAllBookNodeSubNodes = async (bookId, nodeId) => {
  try {
    const response = await httpInterceptor.get(`${url}/books/${bookId}/nodes/${nodeId}/nodes`);
    return response?.data;
  } catch (error) {
    throw extractErrorMessage(error);
  }
};

/**
 * Retrieves a list of books based on the provided discipline and user books.
 *
 * @param {string} discipline - The discipline to filter books by.
 * @param {string[]} userBooks - The list of books associated with the user.
 * @returns {Promise<object[]>} A promise resolving to an array of book objects.
 */
export const getAllBooks = async (discipline, userBooks) => {
  const queryParams = new URLSearchParams({
    discipline: discipline,
    userBooks: userBooks,
  });

  try {
    const response = await httpInterceptor.get(`${url}/books?${queryParams}`);
    return response?.data;
  } catch (error) {
    throw extractErrorMessage(error);
  }
};

export const importAllBooks = async books => {
  try {
    const response = await httpInterceptor.post(`${url}/ptb/books/import`, books);
    return response?.data;
  } catch (error) {
    throw extractErrorMessage(error);
  }
};

export const getDisciplineBooks = async discipline => {
  const queryParams = new URLSearchParams({
    discipline: discipline,
  });

  try {
    const response = await httpInterceptor.get(`${url}/books?${queryParams}`);
    return response?.data;
  } catch (error) {
    throw extractErrorMessage(error);
  }
};

export const saveUserBooks = async books => {
  try {
    const response = await httpInterceptor.post(`${url}/settings/books`, books);
    return response?.data;
  } catch (error) {
    throw extractErrorMessage(error);
  }
};

export const getUserBooks = async () => {
  try {
    const response = await httpInterceptor.get(`${url}/settings/books`);
    return response?.data;
  } catch (error) {
    throw extractErrorMessage(error);
  }
};

export const getUserBooksByID = async bookId => {
  try {
    const response = await httpInterceptor.get(`${url}/books/${bookId}`);
    return response?.data;
  } catch (error) {
    throw extractErrorMessage(error);
  }
};

export const getChaptersByBookID = async (bookId, selectedQuestionTypes) => {
  try {
    const response = await httpInterceptor.get(`${url}/books/${bookId}/nodes?QTyp=${selectedQuestionTypes.join(',')}`);
    return response?.data;
  } catch (error) {
    throw extractErrorMessage(error);
  }
};
