// context/AppContext.js
import { createContext, useContext, useEffect, useState } from 'react';
import { useIntl } from 'react-intl';
import Test from '../entities/Test.Entity';
import { toastify } from '../components/common/Toastify';
import { getUserTestFolders } from '../services/testfolder.service';
import { getFolderTests, getRootTests } from '../services/testcreate.service';
import { convertNodeToQuestion, rearrangeQuestions } from '../utils/questions-utils';
import { getUserQuestionFolders, getUserQuestionFoldersRoot, getUserQuestions } from '../services/userfolder.service';
import QtiService from '../utils/qti-converter';
import { constructTest } from '../utils/test-utils';
import { getUserProfilesettings } from '../services/profile.service';
import { publish } from '../utils/events';

const AppContext = createContext({
  tests: [],
  disciplinesData: {},
  selectedTest: {},
  selectTest: () => {},
  addTest: () => {},
  deleteTestTab: () => {},
  dispatchEvent: () => {},
});
const maxAllowedTestTabsCount = process.env.REACT_APP_MAX_ALLOWED_TEST_TABS_COUNT;

const fetchUserProfileSettings = async dispatch => {
  try {
    dispatch('SHOW_LOADER');
    const data = await getUserProfilesettings();
    dispatch('UPDATE_USER_METADATA', data);
  } catch (error) {
    toastify.showErrorToast(error);
  } finally {
    dispatch('HIDE_LOADER');
  }
};

const AppProvider = ({ children }) => {
  const intl = useIntl();
  const [tests, setTests] = useState([]);
  const [selectedTest, setSelectedTest] = useState();
  const [editTest, setEditTest] = useState(null);
  const [savedFolders, setSavedFolders] = useState([]);
  const [rootFolderGuid, setRootFolderGuid] = useState('');
  const [editTestHighlight, setEditTestHighlight] = useState();
  const [selectedViewTest, setSelectedViewTest] = useState(null);
  const [showDuplicateTestConfimationModal, setShowDuplicateTestConfimationModal] = useState(false);
  const [duplicateQuestion, setDuplicateQuestion] = useState(null);
  const [testTabEdit, setTestTabEdit] = useState(false);
  const [isOpenState, setIsOpenState] = useState({});
  const [clickedNodes, setClickedNodes] = useState([]);
  const questionCount = process.env.REACT_APP_TEST_QUESTIONS_COUNT;

  const [disciplinesData, setDisciplinesData] = useState({
    allDisciplines: [], // stores all disciplines
    userDisciplines: [], // stores user disciplines
    selectedDisciplines: [], // stores currently selected disciplines
    userBooks: [], // stores user books
    selectedBooks: [], // stores currently selected books
  });
  const [loader, setLoader] = useState({
    isLoading: false,
    count: 0,
  });
  const [userMetadata, setUserMetadata] = useState(null);
  const [questionBanksData, setQuestionBanksData] = useState({
    advancedSearchSelection: [],
    selectedBookIds: [],
    selectedBookNodes: [],
    simpleSearchText: '',
  });

  const handleViewTest = node => {
    // setTestName(name.text);
    constructAndActivateTest(node);
  };

  /**
   * Constructs a test from a given node and make it active.
   * @param {Object} node - The node to make the test question from.
   */
  const constructAndActivateTest = async node => {
    const testId = node.guid || node.id;

    // Check if the test already exists
    const existingTest = tests.find(
      test => test.testId === testId || (test.metadata.guid === testId && test.testId === undefined)
    );
    if (existingTest) {
      dispatchEvent('SELECT_TEST', existingTest);
      return;
    }

    if (tests.length >= maxAllowedTestTabsCount) {
      var msg = intl.formatMessage({ id: 'warning.moretests' });
      msg = msg.replace('{$}', maxAllowedTestTabsCount);
      toastify.showWarningToast(msg);
      return;
    }

    try {
      dispatchEvent('SHOW_LOADER');
      // Construct the test
      const test = await constructTest(node);
      // Add the test to the list of tests and make it active
      addTest(test);
    } catch (error) {
      if (error?.message?.response?.request?.status === 409) {
        toastify.showErrorToast(error.message.response.data.message);
      } else {
        toastify.showErrorToast(intl.formatMessage({ id: 'error.Error.fetchingtest' }));
      }
    } finally {
      dispatchEvent('HIDE_LOADER');
    }
  };

  const handleEditTest = node => {
    // setTestName(name.text);
    setEditTest(node);
    constructAndActivateTest(node);
  };

  const handleHideDuplicateModal = () => {
    setShowDuplicateTestConfimationModal(false);
  };

  const handleQuestion = (addDuplicate = false) => {
    if (addDuplicate && duplicateQuestion) {
      const updatedTest = { ...selectedTest };
      updatedTest.questions.push(duplicateQuestion);
      setSelectedTest(updatedTest);
      setShowDuplicateTestConfimationModal(false);
    } else {
      setDuplicateQuestion(null);
    }
  };

  const handleQuestionAdd = node => {
    if (!selectedTest) {
      return;
    }
    if (selectedTest.questions.length >= questionCount) {
      var msg = intl.formatMessage({ id: 'warning.morequestions' });
      msg = msg.replace('{$}', questionCount);
      toastify.showWarningToast(msg);
      return;
    }
    console.log('adding question', node);
    const test = { ...selectedTest };

    const isDuplicate = node.guid && test.questions?.some(existingNode => existingNode.guid === node.guid);

    if (isDuplicate) {
      setDuplicateQuestion(node);
      setShowDuplicateTestConfimationModal(true);
      return;
    }

    insertQuestionAtEnd(node);
  };

  const insertQuestionAtEnd = (node, isDuplicate = false) => {
    const updatedTest = { ...selectedTest };
    updatedTest.questions.push(convertNodeToQuestion(node, isDuplicate));
    setSelectedTest(updatedTest);
  };

  const selectTest = item => {
    const selectedItemIndex = tests.findIndex(test => test.id === item.id);
    if (selectedItemIndex >= 0) {
      let selectedTest = tests[selectedItemIndex];
      // move selected test to the beggining if it is selected from the dropdown
      if (selectedItemIndex > 3) {
        const updatedTests = [...tests];
        selectedTest = updatedTests.splice(selectedItemIndex, 1)[0];
        updatedTests.unshift(selectedTest);
        setTests(updatedTests);
      }
      setSelectedTest(selectedTest);
    }
    // this will publish an event to move the scroll position to top
    publish('active_test_changed');
  };

  const addTest = async newTest => {
    await setSelectedTest(newTest);
    await setTests([newTest, ...tests]);
  };

  const deleteTestTab = testSelected => {
    dispatchEvent('REMOVE_TEST', testSelected);
    let index;

    if (testSelected.guid) {
      index = tests.findIndex(test => test.testId === testSelected.guid || test.metadata.guid === testSelected.guid);
    } else {
      index = tests.findIndex(test => test.id === testSelected.id);
    }

    if (index !== -1) {
      tests.splice(index, 1);
    }
    const updatedTabs = [...tests];

    setTests(updatedTabs);

    if (updatedTabs.length == 0) {
      const newTest = new Test();
      newTest.title = `Untitled ${tests.length + 1}`;

      if (tests.length === 1 && tests[0].title === 'Untitled 1') {
        const untitled1Test = new Test();
        untitled1Test.title = 'Untitled 1';
        addTest(untitled1Test);
      }

      const untitled2Exists = tests.some(test => test.title === 'Untitled 2');
      if (!untitled2Exists) {
        const untitled2Test = new Test();
        untitled2Test.title = 'Untitled 2';
        addTest(untitled2Test);
      }

      addTest(newTest);
    }

    setTests(updatedTabs);
  };

  const fetchUserFolders = async () => {
    dispatchEvent('SHOW_LOADER');
    const rootFolder = await getRootTests();
    setRootFolderGuid(rootFolder.guid);

    Promise.all([getUserTestFolders(rootFolder.guid), getFolderTests(rootFolder.guid)])
      .then(([rootFoldersResponse, folderTestsResponse]) => {
        const combinedData = [...rootFoldersResponse, ...folderTestsResponse];
        setSavedFolders(combinedData);
        combinedData.sort((a, b) => b.sequence - a.sequence);
        localStorage.setItem('savedFolders', JSON.stringify(combinedData));
      })
      .catch(error => {
        console.error('Error getting root folders or folder tests:', error);
        if (error?.message?.response?.request?.status === 409) {
          toastify.showErrorToast(error.message.response.data.message);
        } else {
          toastify.showErrorToast(intl.formatMessage({ id: 'error.Failed.root.FoldersOrFolderTests' }));
        }
      })
      .finally(() => {
        dispatchEvent('HIDE_LOADER');
      });
  };

  const dispatchEvent = (actionType, payload) => {
    switch (actionType) {
      case 'SHOW_LOADER':
        setLoader(loader => ({
          count: loader.count + 1,
          isLoading: true,
        }));
        return;
      case 'HIDE_LOADER':
        setTimeout(() => {
          setLoader(loader => ({
            count: loader.count - 1,
            isLoading: loader.count > 1,
          }));
        }, 100);
        return;
      case 'SELECT_TEST':
        selectTest(payload);
        return;
      case 'ADD_NEW_TEST':
        addTest(payload);
        return;
      case 'REMOVE_TEST':
        setTests(
          tests.filter(
            test => test.id !== payload.id || test.metadata.guid !== payload.guid || test.testId !== payload.guid
          )
        );
        return;
      case 'UPDATE_TEST_TITLE':
        setTests(tests.map(test => (test.id === payload.id ? { ...test, title: payload.title } : test)));

        if (selectedTest && selectedTest.id === payload.id) {
          setSelectedTest({ ...selectedTest, title: payload.title });
        }
        return;
      case 'UPDATE_SELECTED_TEST':
        setSelectedTest(payload.test);
        return;

      case 'HIDE_DUPLICATE_TEST_MODAL':
        setShowDuplicateTestConfimationModal(false);
        return;
      case 'SHOW_DUPLICATE_TEST_MODAL':
        setShowDuplicateTestConfimationModal(true);
        return;
      case 'ADD_DUPLICATE_TEST':
        insertQuestionAtEnd(duplicateQuestion, true);
        setShowDuplicateTestConfimationModal(false);
        return;
      case 'REARRANGE_QUESTIONS': {
        // Extract the drag source ID and drop target ID from the payload
        const { dragSourceId, dropTargetId } = payload;

        // Create a copy of the current questions array
        const questions = [...selectedTest.questions];

        // Call the rearrangeQuestions function to update the questions array
        const updatedQuestions = rearrangeQuestions(questions, dragSourceId, dropTargetId);

        // Update the selected test with the new questions array
        setSelectedTest(current => ({ ...current, questions: updatedQuestions }));

        // Update selected test in the tests array so that questions rearrangements persists on tab changes
        setTests(tests =>
          tests.map(test => (test.id === selectedTest.id ? { ...test, questions: updatedQuestions } : test))
        );

        return;
      }

      case 'ADD_NEW_QUESTION_TO_TEST':
        handleQuestionAdd(payload);
        return;
      case 'DELETE_QUESTION_AT_INDEX':
        setSelectedTest(selectedTest => {
          selectedTest.questions.splice(payload.index, 1);

          return { ...selectedTest };
        });
        return;

      case 'UPDATE_DISCIPLINES_DATA':
        setDisciplinesData({ ...disciplinesData, ...payload });
        return;
      case 'UPDATE_USER_METADATA':
        setUserMetadata(payload);
        return;
      case 'FETCH_USER_METADATA':
        fetchUserProfileSettings(dispatchEvent);
        return;

      case 'UPDATE_ADVANCED_SEARCH_SELECTION':
        setQuestionBanksData(data => ({ ...data, advancedSearchSelection: payload }));
        return;
      case 'CLEAR_ADVANCED_SEARCH_SELECTION':
        setQuestionBanksData(data => ({ ...data, advancedSearchSelection: [], selectedBookIds: [] }));
        return;

      case 'UPDATE_SELECTED_BOOK_IDS':
        setQuestionBanksData(data => ({ ...data, selectedBookIds: payload }));
        return;
      case 'UPDATE_SELECTED_BOOK_NODES':
        setQuestionBanksData(data => ({ ...data, selectedBookNodes: payload }));
        return;

      case 'UPDATE_SIMPLE_SEARCH_TEXT':
        setQuestionBanksData(data => ({ ...data, simpleSearchText: payload }));
        return;
      case 'CLEAR_SIMPLE_SEARCH_TEXT':
        setQuestionBanksData(data => ({ ...data, simpleSearchText: '', selectedBookIds: [] }));
        return;
      default:
        return;
    }
  };

  useEffect(() => {
    if (!tests || (tests && tests.length === 0)) {
      let untitled1Test = tests.find(test => test.title === 'Untitled 1');
      if (!untitled1Test) {
        const defaultTestTab = new Test();
        defaultTestTab.title = 'Untitled 1';
        untitled1Test = defaultTestTab;
        setTests([...tests, untitled1Test]);
      }
      setSelectedTest(untitled1Test);
    }
  }, []);

  return (
    <AppContext.Provider
      value={{
        tests,
        setTests,
        selectedTest,
        setSelectedTest,
        selectTest,
        addTest,
        deleteTestTab,
        dispatchEvent,
        editTest,
        isOpenState,
        setIsOpenState,
        handleEditTest,
        handleViewTest,
        savedFolders,
        setSavedFolders,
        rootFolderGuid,
        setRootFolderGuid,
        fetchUserFolders,
        editTestHighlight,
        setEditTestHighlight,
        setSelectedViewTest,
        selectedViewTest,
        disciplinesData,
        handleHideDuplicateModal,
        showDuplicateTestConfimationModal,
        handleQuestion,

        questionBanksData,

        testTabEdit,
        setTestTabEdit,
        loader,
        userMetadata,
        setClickedNodes,
        clickedNodes,
      }}
    >
      {children}
    </AppContext.Provider>
  );
};

const useAppContext = () => useContext(AppContext);

export { AppProvider, useAppContext };
