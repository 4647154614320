import { CompositeDecorator, ContentState, EditorState, convertFromRaw, convertToRaw } from 'draft-js';
import htmlToDraft from 'html-to-draftjs';
import { createContentStateWithBlanks } from '../utils/blank-utils';
import { BlankButton, InlineImage } from '../utils/decoratorRenderComponents';

/**
 * Updates the block type of unstyled blocks in a content state.
 *
 * @param {ContentState} contentState - The content state to update.
 * @returns {RawDraftContentState} The updated raw content state.
 */
export function updateBlockType(contentState) {
  const rawState = convertToRaw(contentState);
  rawState.blocks = rawState.blocks.map(block => {
    if (block.type === 'unstyled') {
      const { 'text-align': textAlign, 'margin-left': marginLeft } = block.data;

      if (textAlign) {
        block.type = `${textAlign}-align`;
      }

      if (marginLeft) {
        block.depth = parseInt(parseFloat(marginLeft) / 2.5);
        delete block.data['margin-left'];
      }
    }
    return block;
  });

  return rawState;
}

/**
 * Strategy to find image entities in a content block.
 *
 * @param {ContentBlock} contentBlock - The content block to search for image entities.
 * @param {Function} callback - The callback function to call for each image entity found.
 * @param {ContentState} contentState - The content state of the editor.
 */
const findImageEntities = (contentBlock, callback, contentState) => {
  contentBlock.findEntityRanges(character => {
    const entityKey = character.getEntity();
    return entityKey !== null && contentState.getEntity(entityKey).getType() === 'IMAGE';
  }, callback);
};

/**
 * Strategy to find the blank entities in a content block.
 *
 * @param {ContentBlock} contentBlock - The content block to search for image entities.
 * @param {Function} callback - The callback function to call for each image entity found.
 * @param {ContentState} contentState - The content state of the editor.
 */
const findBlankEntities = (contentBlock, callback, contentState) => {
  contentBlock.findEntityRanges(character => {
    const entityKey = character.getEntity();
    return entityKey !== null && contentState.getEntity(entityKey).getType() === 'BLANK';
  }, callback);
};

// Create a decorator
const decorator = new CompositeDecorator([
  {
    strategy: findImageEntities,
    component: InlineImage,
  },
  {
    strategy: findBlankEntities,
    component: BlankButton,
  },
]);

/**
 * Generates a new editor state from an HTML string.
 *
 * @param {string} html - The HTML string to generate the editor state from.
 * @returns {EditorState} The new editor state.
 */
export function generateNewState(html) {
  let newEditorState;

  if (html) {
    const blocksFromHtml = htmlToDraft(html);
    const { contentBlocks, entityMap } = blocksFromHtml;
    let contentState = ContentState.createFromBlockArray(contentBlocks, entityMap);
    contentState = createContentStateWithBlanks(contentState, html);
    contentState = convertFromRaw(updateBlockType(contentState));
    newEditorState = EditorState.createWithContent(contentState, decorator);
    newEditorState = EditorState.moveSelectionToEnd(newEditorState);
  } else {
    newEditorState = EditorState.createEmpty(decorator);
  }

  return newEditorState;
}
