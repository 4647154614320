import React, { useEffect, useState } from 'react';
import { Button, Dropdown, DropdownButton, Modal, Nav } from 'react-bootstrap';
import { FormattedMessage, useIntl } from 'react-intl';
import deepEqual from 'deep-equal';
import { useAppContext } from '../../context/AppContext';
import Test from '../../entities/Test.Entity';
import QtiService from '../../utils/qti-converter';
import { getFolderTests, saveMyQuestions, saveMyTest } from '../../services/testcreate.service';
import { toastify } from '../common/Toastify';
import SaveAsModalPopup from '../modals/SaveAs/SaveAsModalPopup';
import PrintTestModalpopup from './PrintTest/PrintTestModalpopup';
import ExportModalPopup from '../modals/Export/ExportModalPopup';
import { isTestReadyToSave } from '../../utils/test-utils';
import { publish } from '../../utils/events';
import './TestTabs.css';

const TestTabs = () => {
  const intl = useIntl();
  const {
    tests,
    setTests,
    addTest,
    deleteTestTab,
    selectedTest,
    dispatchEvent,
    editTest,
    fetchUserFolders,
    setEditTestHighlight,
    setSelectedViewTest,
    setClickedNodes,
  } = useAppContext();

  const [showSaveAsModal, setShowSaveAsModal] = useState(false);
  const [showPrintModal, setShowPrintModal] = useState(false);
  const [showModalExport, setShowModalExport] = useState(false);
  const [showDeleteConfirmationModal, setShowDeleteConfirmationModal] = useState(false);
  const maxAllowedTestTabsCount = process.env.REACT_APP_MAX_ALLOWED_TEST_TABS_COUNT;
  useEffect(() => {
    if (editTest !== null) {
      handleEditTestTab(editTest);
    }
  }, [editTest]);

  const handleTestSelection = item => {
    dispatchEvent('SELECT_TEST', item);
  };

  const handleAddNewTestTab = event => {
    event.preventDefault();
    const newTest = new Test();
    newTest.title = `Untitled ${tests.length + 1}`;

    // Check if there is only one tab and it is "Untitled 1"
    if (tests.length === 1 && tests[0].title === 'Untitled 1') {
      // If it's "Untitled 1", add a closing tab for "Untitled 1"
      const untitled1Test = new Test();
      untitled1Test.title = 'Untitled 1';
      addTest(untitled1Test);
    } else if (tests.length >= maxAllowedTestTabsCount) {
      var msg = intl.formatMessage({ id: 'warning.moretabs' });
      msg = msg.replace('{$}', maxAllowedTestTabsCount);
      toastify.showWarningToast(msg);
      return;
    }

    // Check if "Untitled 2" tab exists, if not, add it
    const untitled2Exists = tests.some(test => test.title === 'Untitled 2');
    if (!untitled2Exists) {
      const untitled2Test = new Test();
      untitled2Test.title = 'Untitled 2';
      addTest(untitled2Test);
    }

    // Add the new test
    addTest(newTest);

    // Select the new test
    // dispatchEvent('SELECT_TEST', newTest);
  };

  const handleEditTestTab = editTest => {
    const existingTest = tests.find(test => test.id === editTest?.id);
    if (existingTest) {
      // If the test exists, select it
      dispatchEvent('SELECT_TEST', existingTest);
    } else {
      // If the test does not exist, create a new one
      const newTest = new Test();
      newTest.title = editTest?.text;
      newTest.id = editTest?.id;
      // addTest(newTest);
    }
  };

  const handleDeleteTab = (e, test) => {
    if (test.questions.some(question => !deepEqual(question.qtiModel, question.masterData))) {
      setShowDeleteConfirmationModal(true);
    } else {
      removeTab(e, test);
      setShowDeleteConfirmationModal(false);
    }
  };

  const handleCancel = () => {
    setShowDeleteConfirmationModal(false);
  };

  const handleDontSave = (e, testSelected) => {
    removeTab(e, testSelected);
    setShowDeleteConfirmationModal(false);
  };

  const removeTab = (e, testSelected) => {
    e.preventDefault();
    e.stopPropagation();

    if (!testSelected) {
      // If testSelected is undefined, handle the case gracefully
      console.error('Error: testSelected is undefined');
      return;
    }

    const index = tests.findIndex(test => test.id === testSelected.id);
    const newSelectedTest =
      tests[index - 1] || // Try selecting the previous test
      tests[index + 1] || // If previous test doesn't exist, try selecting the next test
      tests.find(test => test.title.startsWith('Untitled')); // If both previous and next tests don't exist, select an untitled test

    if (newSelectedTest) {
      dispatchEvent('SELECT_TEST', newSelectedTest);
    }

    deleteTestTab(testSelected);
    setEditTestHighlight(selectedTest.id);
    setSelectedViewTest(null);
    setShowDeleteConfirmationModal(false);
  };

  const handleSave = async (e, activeTest, folderId, newTestName) => {
    const test = { ...activeTest };
    let buttonName = e.target.name;
    // 1. Check for duplicate test
    // 2. Save questions
    // 3. Save tests
    if (!isTestReadyToSave(test)) {
      setShowDeleteConfirmationModal(false);
      toastify.showWarningToast(intl.formatMessage({ id: 'warning.noQuestionsOrNotInEditState' }));
      return;
    }

    // Folder Id will be passed from save As modal popup
    if (folderId) {
      test.folderGuid = folderId;
    } else {
      const folderGuid = JSON.parse(sessionStorage.getItem('selectedFolderId'));
      test.folderGuid = folderGuid;
    }

    if (buttonName === 'saveAs') {
      test.title = activeTest.title;
    }

    let isduplicateTest = await isDuplicateTest(buttonName, test);
    // When duplicate method fails because of server error dont proceed with test save
    if (isduplicateTest === null) {
      toastify.showErrorToast(intl.formatMessage({ id: 'error.somethingWentWrong' }));
      return;
    } else if (isduplicateTest) {
      toastify.showWarningToast(intl.formatMessage({ id: 'warning.duplicateTest' }));
    } else {
      // Proceed to save when its update or new test
      if (!test.title.trim()) {
        // If the test title is empty or only contains whitespace, set it to a default value
        toastify.showWarningToast(intl.formatMessage({ id: 'warning.testNameEmpty' }));
        return;
      }
      // Reset Guid of test if its save As
      if (buttonName === 'saveAs') {
        test.testId = null;
        test.metadata.guid = null;
      }
      let questionBindings = await saveQuestions(test);
      if (questionBindings && questionBindings.length != 0) {
        saveTest(test, questionBindings);
      }
    }
    setShowDeleteConfirmationModal(false);
  };

  const saveTest = async (test, questionBindings) => {
    // Building the json to create the test.
    var testcreationdata = {
      metadata: {
        crawlable: 'true',
      },
      body: {
        '@context': 'http://purl.org/pearson/paf/v1/ctx/core/StructuredAssignment',
        '@type': 'StructuredAssignment',
        assignmentContents: {
          '@contentType': 'application/vnd.pearson.paf.v1.assignment+json',
          binding: [],
        },
      },
    };

    testcreationdata.body.title = test.title;
    testcreationdata.body.guid = null; // TODO : Set this value

    // TODO: Update this logic based on understanding of what testID represents
    if (test.testId != null || test.metadata.guid != null) {
      testcreationdata.metadata = test.metadata;
      if (test.testId) {
        testcreationdata.metadata.guid = test.testId;
      } else {
        testcreationdata.metadata.guid = test.metadata.guid;
      }
    }

    testcreationdata.metadata.title = test.title;
    testcreationdata.body.assignmentContents.binding = questionBindings;
    try {
      let testResult = await saveMyTest(testcreationdata, test.folderGuid);
      // update GUID the saved test object
      if (testResult) {
        test.testId = testResult.guid;
        test.metadata.guid = testResult.guid;

        // Updating selected test with testID
        dispatchEvent('UPDATE_SELECTED_TEST', { test });
        fetchUserFolders();
        toastify.showSuccessToast(intl.formatMessage({ id: 'success.testSaved' }));
        setClickedNodes([]);
        setTests(tests);
        setShowSaveAsModal(false);
        setEditTestHighlight(selectedTest.id);
      }
    } catch (error) {
      console.error('Error saving Test:', error);
      if (error?.message?.response?.request?.status === 409) {
        toastify.showErrorToast(error.message.response.data.message);
      } else {
        toastify.showErrorToast(intl.formatMessage({ id: 'error.testSave' }));
      }
    }
    sessionStorage.removeItem('selectedFolderId');
  };

  const saveQuestions = async test => {
    // Save the Question Envelops & return the question bindings to attach to test
    // TODO - Add additional logic from legacy app as needed
    let questionEnvelops = [];
    let questionBindings = [];
    let userSettings = {};

    if (test.questions && test.questions.length > 0) {
      test.questions.forEach((qstn, index) => {
        questionEnvelops.push(buildQuestionEnvelop(qstn, userSettings));
      });
    }
    try {
      const questionResults = await saveMyQuestions(questionEnvelops);
      questionResults.forEach((qstnResult, index) => {
        questionBindings.push({
          guid: qstnResult.guid,
          activityFormat: 'application/vnd.pearson.qti.v2p1.asi+xml',
          bindingIndex: index,
        });
        // update question data if question save is success. This is to prevent consecutive save actions.
        // Guid is updated with response of api. MasterData is updated to latest qtiModel calculated during last save which was successfull
        test.questions[index].guid = qstnResult.guid;
        test.questions[index].masterData = JSON.parse(JSON.stringify(test.questions[index].qtiModel));
      });

      // publish event to reload your questions tab
      publish('reload_your_questions');
    } catch (error) {
      console.error('Error saving Questions:', error);
      if (error?.message?.response?.request?.status === 409) {
        toastify.showErrorToast(error.message.response.data.message);
      } else {
        toastify.showErrorToast(intl.formatMessage({ id: 'error.testSave' }));
      }
    }
    return questionBindings;
  };

  const buildQuestionEnvelop = (qstn, userSettings) => {
    qstn.data = QtiService.getQtiXML(qstn);
    qstn.IsEdited = false;
    if (qstn.qtiModel.EditAttempted && !deepEqual(qstn.masterData, qstn.qtiModel)) {
      qstn.IsEdited = true;
    }
    var qstnExtMetadata = buildQuestionMetadata(qstn, userSettings);
    var QuestionEnvelop = {
      metadata: {
        guid: qstn.IsEdited ? null : qstn.guid,
        title: qstn.title,
        description: qstn.description,
        quizType: qstn.quizType,
        subject: qstn.subject,
        timeRequired: qstn.timeRequired,
        crawlable: qstn.crawlable,
        keywords: qstn.keywords,
        versionOf: qstn.versionOf,
        version: qstn.version,
        extendedMetadata: qstnExtMetadata,
      },
      body: qstn.data,
    };

    return QuestionEnvelop;
  };

  const buildQuestionMetadata = (qstn, userSettings) => {
    var qstnExtMetadata = [];

    if (Array.isArray(qstn.extendedMetadata)) {
      return qstn.extendedMetadata;
    }

    if (qstn && qstn.extendedMetadata) {
      for (let key in qstn.extendedMetadata) {
        if (Object.prototype.hasOwnProperty.call(qstn.extendedMetadata, key)) {
          qstnExtMetadata.push({
            name: key,
            value: qstn.extendedMetadata[key],
          });
        }
      }
    }
    return qstnExtMetadata;
  };

  const isDuplicateTest = async (buttonName, test) => {
    let testStatus = await duplicateTestStatus(test);
    //status 0 = No duplicates.
    //status 1 = Duplicate Title but guid is different. Prevent save
    //status 2 = Duplicate Guid but title is different. So set testId to empty to save new test
    //status 3 = both guid & title are same (Update).
    if (testStatus === 0) {
      return false;
    } else if (testStatus === 1) {
      return true;
    } else if (testStatus === 2) {
      if (buttonName && buttonName === 'saveAs') {
        test.testId = null;
      }
      return false;
    } else if (testStatus === 3) {
      if (buttonName && buttonName === 'saveAs') {
        return true; // prevent update during save As
      } else {
        return false;
      }
    }

    return null;
  };

  const duplicateTestStatus = async test => {
    try {
      var isDuplicate = 0;
      const folderTests = await getFolderTests(test.folderGuid);
      // If test.title === folderTest.title && test.testId == folderTest.guid, this test is being updated
      // if only test.title match OR if only Guid match, then its duplicate
      // if both does not match then its new test save
      for (const folderTest of folderTests) {
        if (folderTest.title == test.title && folderTest.guid == test.testId) {
          isDuplicate = 3; // Both GUID & Title match
          break;
        } else if (folderTest.guid == test.testId || folderTest.guid == test.metadata.guid) {
          isDuplicate = 2; // Same GUID with different title
          break;
        } else if (folderTest.title == test.title) {
          isDuplicate = 1; // Same title with different GUID
          break;
        }
      }
      console.log('Duplicate test status : ', isDuplicate);
      return isDuplicate;
    } catch (error) {
      console.error('Error fetching folder tests:', error);
    }
  };

  const handleShowModal = () => {
    setShowSaveAsModal(true);
  };

  const handleCloseModal = e => {
    setShowSaveAsModal(!showSaveAsModal);

    e?.preventDefault();
    e?.stopPropagation();
  };

  // const handleClosePrintModal = (event) => {
  //   setShowPrintModal(!showPrintModal);
  //   event.preventDefault();
  //   event.stopPropagation();
  // };

  const handleSaveAs = () => {
    if (!isTestReadyToSave(selectedTest)) {
      toastify.showWarningToast(intl.formatMessage({ id: 'warning.noQuestionsOrNotInEditState' }));
      return;
    }
    setShowSaveAsModal(true);
  };

  const handleShowModalExport = () => {
    const questions = selectedTest.questions;
    const isAnyQuestionEdited = questions.some(qstn => {
      const isEdited = qstn.qtiModel.EditAttempted && !deepEqual(qstn.masterData, qstn.qtiModel);
      return isEdited;
    });

    if (isAnyQuestionEdited) {
      toastify.showWarningToast(intl.formatMessage({ id: 'warning.saveTestBeforeExport' }));
      return;
    }

    if (selectedTest.testId) {
      setShowModalExport(true);
    } else {
      toastify.showWarningToast(intl.formatMessage({ id: 'warning.saveTestBeforeExport' }));
    }
  };

  const handlePrint = () => {
    // Open print modal
    if (!isTestReadyToSave(selectedTest)) {
      toastify.showWarningToast(intl.formatMessage({ id: 'warning.noQuestionsOrNotInEditState' }));
      return;
    }
    setShowPrintModal(true);
  };

  return (
    <div className="tab-container">
      <div className="d-flex flex-column flex-sm-row justify-content-between">
        <h4 className="p-1">
          <FormattedMessage id="testtabs.title" />
        </h4>
        <div className="p-1 d-flex flex-column flex-sm-row align-items-start align-items-sm-center">
          <Button className="btn-test mr-1" id="btn-test-wizard">
            <i className="fa-solid fa-wand-magic-sparkles"></i>
            <FormattedMessage id="testtabs.testwizard" />
          </Button>

          <div className="d-flex flex-column flex-sm-row align-items-start">
            <DropdownButton id="dropdown-item-button" title="Save" className="btn-test mb-1 mb-sm-0 mr-sm-1 mr-1">
              <Dropdown.Item
                href="#"
                onClick={e => handleSave(e, selectedTest)}
                disabled={selectedTest && selectedTest.ismigrated}
              >
                <FormattedMessage id="testtabs.save" />
              </Dropdown.Item>
              <Dropdown.Item href="#" onClick={e => handleSaveAs(e, selectedTest)}>
                <FormattedMessage id="testtabs.saveas" />
              </Dropdown.Item>
            </DropdownButton>

            <Button id="dropdown-item-button" className="btn-test mb-1 mb-sm-0 mr-sm-1 mr-1" onClick={handlePrint}>
              <FormattedMessage id="testtabs.print" />
            </Button>

            <Button className="btn-test mt-1 mt-sm-0" onClick={handleShowModalExport}>
              <FormattedMessage id="testtabs.export" />
            </Button>
            {showPrintModal && (
              <PrintTestModalpopup
                width="80%"
                show={showPrintModal}
                handleCloseModal={() => setShowPrintModal(false)}
              />
            )}
            <ExportModalPopup
              width="80%"
              show={showModalExport}
              selectedTest={selectedTest}
              handleCloseModal={() => setShowModalExport(false)}
            />
            {showSaveAsModal && (
              <SaveAsModalPopup
                show={showSaveAsModal}
                handleCloseModal={handleCloseModal}
                selectedTest={selectedTest}
                handleSave={handleSave}
              />
            )}
          </div>
        </div>
      </div>

      <div className="tabs-and-buttons-container">
        <Nav variant="tabs">
          <Nav.Item>
            <Nav.Link
              href="#"
              onClick={handleAddNewTestTab}
              // disabled={tests.length >= maxAllowedTestTabsCount}
              className={'active custom-add-new-test'}
              aria-label="Add New Test"
              title={intl.formatMessage({ id: 'addNewTestTitle', defaultMessage: 'Add New Test' })}
            >
              <i className="fa-solid fa-plus"></i>
            </Nav.Link>
          </Nav.Item>
          <span className="tab-separator"> </span>
          {tests.slice(0, 4).map(test => (
            <Nav.Item key={test.id}>
              <Nav.Link
                onClick={() => {
                  handleTestSelection(test);
                }}
                className={selectedTest && selectedTest.id === test.id ? 'active' : ''}
                id="test-tabs-navlink"
              >
                <div className="tab-label">
                  <div className="test-title" title={test.title}>
                    {test.title.trim() !== '' ? test.title : 'Untitled'}
                  </div>
                  {/* Always render the close button */}
                  {tests.length > 1 && (
                    <div className="">
                      <Button
                        className="close-tab"
                        aria-label="close"
                        aria-roledescription=" "
                        variant="link"
                        onClick={e => handleDeleteTab(e, test)}
                        title=""
                      >
                        <i className="fas fa-times"></i>
                      </Button>
                    </div>
                  )}
                </div>
              </Nav.Link>
            </Nav.Item>
          ))}
          {tests.length > 4 && (
            <Nav.Item className="three-dots-link">
              <Dropdown>
                <Dropdown.Toggle
                  id="dropdown-ellipsis"
                  as={Nav.Link}
                  title={intl.formatMessage({ id: 'message.more', defaultMessage: 'More' })}
                >
                  <i className="fa-solid fa-ellipsis"></i>
                </Dropdown.Toggle>
                <Dropdown.Menu>
                  {tests.slice(4).map(test => (
                    <Dropdown.Item
                      key={test.id}
                      onClick={() => handleTestSelection(test)}
                      className={selectedTest && selectedTest.id === test.id ? 'active' : ''}
                    >
                      <div className="tab-label" id="tab-label-dropdown">
                        <div className="test-title" title={test.title}>
                          {test.title.trim() !== '' ? test.title : 'Untitled'}
                        </div>
                        {/* Always render the close button */}
                        {tests.length > 1 && (
                          <div className="close-tab-wrapper" aria-roledescription=" ">
                            <Button
                              className="close-tab"
                              id="close-tab-dropdown"
                              aria-label="close"
                              aria-roledescription=" "
                              variant="link"
                              onClick={e => handleDeleteTab(e, test)}
                              title=""
                            >
                              <i className="fas fa-times"></i>
                            </Button>
                          </div>
                        )}
                      </div>
                    </Dropdown.Item>
                  ))}
                </Dropdown.Menu>
              </Dropdown>
            </Nav.Item>
          )}
        </Nav>
      </div>

      <Modal show={showDeleteConfirmationModal} onHide={handleCancel}>
        <Modal.Header closeButton>
          <Modal.Title>
            <FormattedMessage id="testtabs.modalpopup.testsaved" />
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <FormattedMessage id="testtabs.modalpopup.savechanges" />
        </Modal.Body>
        <Modal.Footer>
          <Button variant="primary" name="save" onClick={e => handleSave(e, selectedTest)}>
            <FormattedMessage id="testtabs.modalpopup.save" />
          </Button>
          <Button variant="primary" onClick={e => handleDontSave(e, selectedTest)}>
            <FormattedMessage id="testtabs.modalpopup.dontsave" />
          </Button>
          <Button variant="secondary" onClick={handleCancel}>
            <FormattedMessage id="testtabs.modalpopup.cancel" />
          </Button>
        </Modal.Footer>
      </Modal>
    </div>
  );
};

export default TestTabs;
