import React, { useState } from 'react';
import { useDrag } from 'react-dnd';
import DraggableQuestion from './DraggableQuestion';

/**
 * Returns the key for the given node.
 * @param {Object} node - The node to get the key for.
 *
 * @returns {string} The key for the node.
 */

const getKey = node => {
  const keyFuncs = {
    question: node => node.questionGuid,
    node: node => node.nodeGuid,
    book: node => node.bookGuid,
    discipline: node => node.id,
  };

  return keyFuncs[node.type](node);
};

const DraggableNode = ({
  node,
  isOpen,
  onToggle,
  onDataUpdate,
  onBookSelection,
  isClicked,
  lastSelectedQuestion,
  handleAdd,
  setOpen,
}) => {
  const [, drag] = useDrag({
    type: 'TREE_NODE',
    item: { node },
  });

  const handleNodeSelection = e => {
    e.stopPropagation();
    if (node.type === 'book' && onBookSelection) {
      onBookSelection(node);
    }
  };

  const handleCaretClick = (e, isOpen) => {
    e.stopPropagation();
    onToggle();
    if (!isOpen) {
      onDataUpdate && onDataUpdate(node);
    }
  };

  const handleKeyDown = event => {
    if (event.key === 'Enter' || event.key === ' ') {
      event.preventDefault();
      setOpen(!isOpen); // Toggle isOpen state
    }
  };

  return (
    <div
      key={getKey(node)}
      ref={drag}
      className={`question-banks-tree-node ${isClicked ? 'clicked' : ''}`}
      tabIndex="0"
    >
      {node.droppable && (
        <span
          className="caret-container"
          onClick={e => handleCaretClick(e, isOpen)}
          onKeyDown={handleKeyDown}
          tabIndex="0"
        >
          {isOpen ? <i className="bi bi-caret-down-fill"></i> : <i className="bi bi-caret-right-fill"></i>}
        </span>
      )}
      {node.type === 'question' ? (
        <DraggableQuestion
          key={getKey(node)}
          question={node.question}
          index={node.index}
          selectedQuestionId={lastSelectedQuestion}
          handleAdd={handleAdd}
        />
      ) : (
        <span className="tree-node-text" onClick={handleNodeSelection}>
          {node.text}
        </span>
      )}
    </div>
  );
};

export default DraggableNode;
