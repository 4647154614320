import { useEffect, useRef } from 'react';
import { Outlet } from 'react-router-dom';
import './HomePage.css';
import ResourceTab from '../../components/ResourceTab';
import TestTabs from '../../components/testTabs/TestTabs';
import TestCreate from '../../components/TestCreate';
import { subscribe, unsubscribe } from '../../utils/events';

const HomePage = () => {
  console.log('HomePage on load');
  const testContainerRef = useRef();

  const scrollToTop = () => {
    testContainerRef.current.scrollTo(0, 0);
  };

  useEffect(() => {
    subscribe('active_test_changed', scrollToTop);

    return () => {
      unsubscribe('active_test_changed', scrollToTop);
    };
  }, []);

  return (
    <>
      <div className="row scree-height">
        <div className="col-6 panel panel-left">
          <ResourceTab />
          <div className="container-resource">
            <Outlet />
          </div>
        </div>
        <div className="col-6 panel panel-right">
          <TestTabs />
          <div ref={testContainerRef} className="container-test">
            <TestCreate />
          </div>
        </div>
      </div>
    </>
  );
};
export default HomePage;
