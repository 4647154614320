import { useEffect, useState } from 'react';
import { FormattedMessage, useIntl } from 'react-intl';
import { useQuestionsContext } from '../../providers/Questions/QuestionsContext';
import YourQuestionsTreeView from './YourQuestionsTreeView';
import AddFolder from '../AddFolder';
import { toastify } from '../common/Toastify';
import { subscribe, unsubscribe } from '../../utils/events';
import './YourQuestions.css';

/**
 * YourQuestions component
 */
const YourQuestions = () => {
  const intl = useIntl();
  const { treeDataAsArray, addNewFolder, editFolderName, loadInitialQuestionFoldersData } = useQuestionsContext();
  const [selectedFolder, setSelectedFolder] = useState();

  /**
   * Load initial question folders data and subscribe to 'reload_your_questions' event
   */
  useEffect(() => {
    // Load initial question folders data when the component mounts
    loadInitialQuestionFoldersData();

    // Subscribe to the 'reload_your_questions' event to reload data when triggered
    subscribe('reload_your_questions', loadInitialQuestionFoldersData);

    // Return a cleanup function to unsubscribe from the event when the component unmounts
    return () => {
      // Unsubscribe from the 'reload_your_questions' event to prevent memory leaks
      unsubscribe('reload_your_questions', loadInitialQuestionFoldersData);
    };
  }, []);

  /**
   * Handle saving a folder
   *
   * @param {string} folderName The folder name to save
   * @param {boolean} isEditing Whether the folder is being edited or added
   * @returns {void}
   */
  const handleSaveFolder = async (folderName, isEditing) => {
    const trimmedFolderName = folderName.trim();

    // Check if the folder name is empty
    if (!trimmedFolderName) {
      toastify.showErrorToast(intl.formatMessage({ id: 'error.FolderNameCannotBeEmpty' }));
      return;
    }

    try {
      // Determine whether to edit an existing folder or add a new one
      if (isEditing) {
        // Edit the folder name
        editFolderName(trimmedFolderName, selectedFolder);
        // clear folder selection
        setSelectedFolder(null);
      } else {
        // Add a new folder
        addNewFolder(trimmedFolderName);
      }
    } catch (error) {
      // Handle errors when saving the folder
      if (error?.message?.response?.request?.status === 409) {
        toastify.showErrorToast(error.message.response.data.message);
      } else {
        toastify.showErrorToast(intl.formatMessage({ id: 'error.FailedToSaveFolder' }));
      }
    }
  };

  /**
   * Handle editing a folder name
   *
   * @param {object} node The folder node being edited
   * @returns {void}
   */
  const handleEditFolderName = node => {
    setSelectedFolder(node);
  };

  /**
   * Handle closing the add folder dialog
   *
   * @returns {void}
   */
  const handleAddFolderClose = () => {
    setSelectedFolder(null);
  };

  return (
    <div className="p-2 flex-column">
      <AddFolder name={selectedFolder?.text || ''} onFolderAdd={handleSaveFolder} onClose={handleAddFolderClose} />
      {treeDataAsArray.length > 0 || treeDataAsArray.length > 0 ? (
        <div className="saved-folders">
          <YourQuestionsTreeView selectedFolder={selectedFolder} onEditFolderNameClick={handleEditFolderName} />
        </div>
      ) : (
        <div className="no-folders">
          <div>
            <i className="far fa-lightbulb light-orange" aria-label="bulb icon" tabIndex="0"></i>
            <b className="question-title">
              <FormattedMessage id="noSavedQuestion" />
            </b>
          </div>
          <p>
            <FormattedMessage id="EmptySavedQuestion" />
          </p>
          <p>
            <FormattedMessage id="infoCreateTest" />
          </p>
          <p>
            <FormattedMessage id="infoCustomQuestion" />
          </p>
        </div>
      )}
    </div>
  );
};

export default YourQuestions;
