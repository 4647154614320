import { createContext, useContext, useEffect, useState } from 'react';
import User from '../entities/User.Entity';
import auth from '../services/Auth';
import callLoginEndpoint from '../services/authentication';
import { toastify } from '../components/common/Toastify';
import { useIntl } from 'react-intl';
import { useNavigate } from 'react-router-dom';

const AuthContext = createContext();

export const AuthProvider = ({ children }) => {
  const intl = useIntl();
  const navigate = useNavigate();
  const currentUser = JSON.parse(sessionStorage.getItem('user') || '{}');
  const [user, setUser] = useState(currentUser);
  const [isAuthFetching, setIsAuthFetching] = useState(true);
  const [isAuthenticated, setIsAuthenticated] = useState(false);

  useEffect(() => {
    window.addEventListener('unload', handleUnload);
    login();
    return () => {
      window.removeEventListener('unload', handleUnload);
    };
  }, []);

  useEffect(() => {
    const timestamp = sessionStorage.getItem('sessionTimestamp');
    if (!timestamp) {
      logout();
    }
  }, []);

  const handleUnload = () => {
    sessionStorage.setItem('sessionTimestamp', Date.now().toString());
  };

  /**
   * Logs in the user and retrieves their details.
   * @returns {Promise} A promise that resolves when the user is logged in and their details are retrieved.
   */
  const login = async () => {
    try {
      const token = await auth.login();

      await getUserDetails(token);
    } catch (error) {
      toastify.showErrorToast(error);
    }
  };

  /**
   * Logs out the current user and clears the user state.
   *
   * @returns {Promise} A promise that resolves when the logout is complete.
   */
  const logout = async () => {
    try {
      setIsAuthFetching(true);
      await auth.logout();
      setUser(new User());
      sessionStorage.removeItem('sessionTimestamp');
    } catch (error) {
      toastify.showErrorToast(error);
    }
  };

  /**
   * Retrieves user details from the auth endpoint and updates the application state accordingly.
   *
   * @param {string} token - The authentication token to use for the auth endpoint.
   *
   * @returns {Promise<void>} A promise that resolves when the user details have been retrieved and the application state has been updated.
   */
  const getUserDetails = async token => {
    try {
      const response = await callLoginEndpoint(token);
      const { emailAddress, familyName, givenName } = response;

      const currentUser = new User(givenName, familyName, emailAddress);
      setUser(currentUser);
      window.sessionStorage.setItem('user', JSON.stringify(currentUser));

      setIsAuthFetching(false);

      if (response.isLogged === 'false') {
        navigate('/welcomescreen');
      }

      setIsAuthenticated(true);
    } catch (error) {
      console.error('Error logging in:', error);
      let message = intl.formatMessage({ id: 'error.somethingWentWrong' });

      if (error.response.status === 403) {
        message = intl.formatMessage({ id: 'warning.UserisInvalid' });
      }

      toastify.showWarningToast(message);
      setTimeout(logout, 800);
    }
  };

  return (
    <AuthContext.Provider value={{ isAuthFetching, logout, user, isAuthenticated }}>{children}</AuthContext.Provider>
  );
};

export const useAuth = () => {
  return useContext(AuthContext);
};
