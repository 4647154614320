import DOMPurify from 'dompurify';
import jquery from 'jquery';
import { useIntl } from 'react-intl';
import { useAppContext } from '../../context/AppContext';
import FillInBlanksEditView from './edit-view/FillInBlanks/FillInBlanksEditView';

export const getPrintModeFbCaption = (Caption, blanks) => {
  try {
    const htmlText = Caption.trim().replaceAll('&amp;nbsp;', ' ').replaceAll('&lt;', '<').replaceAll('&gt;', '>');
    const element = jquery('<p></p>');
    jquery(element).append(htmlText);
    element.find('button').each(function (i, obj) {
      let blankSpace = "<span class='blank'> _____________________ </span>";
      switch (blanks[i].blankSpace) {
        case 100:
          blankSpace =
            "<span class='blank'>____________________________________________________________________________________________________</span>";
          break;
        case 50:
          blankSpace = "<span class='blank'>__________________________________________________</span>";
          break;
        default:
          blankSpace = "<span class='blank'>____________________</span>";
      }
      jquery(obj).replaceWith(blankSpace);
    });
    return element[0].innerHTML;
  } catch (e) {}
};

const FillInBlanks = props => {
  const { questionNode, questionNodeIndex, onQuestionStateChange, onQuestionDelete } = props;
  const intl = useIntl();
  const { Caption, Blanks } = questionNode.qtiModel;

  const handleEdit = e => {
    e.preventDefault();
    questionNode.qtiModel.EditOption = true;
    questionNode.qtiModel.EditAttempted = true;
    onQuestionStateChange(true);
  };

  const handleRemoveQuestion = e => {
    e.preventDefault();
    if (questionNode.qtiModel.EditOption) {
      questionNode.qtiModel.EditOption = false;
      onQuestionDelete(questionNodeIndex);
      onQuestionStateChange(false);
    } else {
      onQuestionDelete(questionNodeIndex);
      onQuestionStateChange(false);
    }
  };

  const sanitizedData = data => ({
    __html: DOMPurify.sanitize(data),
  });

  const getPrintOnlyView = () => {
    return (
      <div className="mb-1 d-flex align-items-center m-2 question-container">
        <div className="flex-grow-1 d-flex ml-7 d-flex align-items-start">
          <div className="mr-2 pl-1"> {questionNodeIndex + 1}) </div>
          <div
            className="view-content flex-grow-1"
            dangerouslySetInnerHTML={sanitizedData(getPrintModeFbCaption(Caption, Blanks))}
          ></div>
        </div>
      </div>
    );
  };

  const getPrintWithEditView = () => {
    return (
      <div className="mb-3 d-flex align-items-center m-2 question-container">
        <div className="flex-grow-1 d-flex ml-7 d-flex align-items-start">
          <div className="mr-2 pl-1"> {questionNodeIndex + 1}) </div>
          <div
            className="view-content flex-grow-1"
            dangerouslySetInnerHTML={sanitizedData(getPrintModeFbCaption(Caption, Blanks))}
          ></div>
        </div>
        <div className="mr-7 d-flex align-items-center d-flex justify-content-end align-self-start">
          <button
            className="editbtn"
            onClick={handleEdit}
            title={intl.formatMessage({ id: 'editButtonTitle', defaultMessage: 'Edit' })}
          >
            <i className="bi bi-pencil-fill"></i>
          </button>
          <button
            className="deletebtn"
            onClick={handleRemoveQuestion}
            title={intl.formatMessage({ id: 'deleteButtonTitle', defaultMessage: 'Remove' })}
          >
            <i className="bi bi-trash"></i>
          </button>
        </div>
      </div>
    );
  };

  const getPrintWithAnswerView = () => {
    return (
      <div className="mb-1 d-flex align-items-center m-2 question-container">
        <div className="flex-grow-1 d-flex ml-7 d-flex align-items-start">
          <div className="mr-2 pl-1"> {questionNodeIndex + 1}) </div>
          <div
            className="view-content flex-grow-1"
            dangerouslySetInnerHTML={sanitizedData(getPrintModeFbCaption(Caption, Blanks))}
          ></div>
        </div>
      </div>
    );
  };

  const getPrintView = viewId => {
    if (viewId == 3) {
      return getPrintWithAnswerView();
    } else if (viewId == 2) {
      return getPrintWithEditView();
    } else {
      return getPrintOnlyView();
    }
  };

  return (
    <div id={questionNode.itemId}>
      {!questionNode.qtiModel.EditOption ? getPrintView(props.printView) : <FillInBlanksEditView {...props} />}
    </div>
  );
};
export default FillInBlanks;
