import Essay from '../../components/questions/Essay';
import FillInBlanks from '../../components/questions/FillInBlanks';
import Matching from '../../components/questions/Matching';
import MultipleChoice from '../../components/questions/MultipleChoice';
import MultipleResponse from '../../components/questions/MultipleResponse';
import TrueFalse from '../../components/questions/TrueFalse';
import CustomQuestionsService from '../../services/CustomQuestionsService';

const DraggableQuestion = ({ question, index }) => {
  const key = question.guid;
  const { qtiModel } = question;

  switch (question.metadata.quizType) {
    case CustomQuestionsService.MultipleChoice:
      return (
        <div key={key}>
          <MultipleChoice questionNode={question} questionNodeIndex={index} qtiModel={qtiModel} printView={3} />
        </div>
      );
    case CustomQuestionsService.MultipleResponse:
      return (
        <div key={key}>
          <MultipleResponse questionNode={question} questionNodeIndex={index} qtiModel={qtiModel} printView={3} />
        </div>
      );
    case CustomQuestionsService.TrueFalse:
      return (
        <div key={key}>
          <TrueFalse questionNode={question} questionNodeIndex={index} qtiModel={qtiModel} printView={3} />
        </div>
      );
    case CustomQuestionsService.Matching:
      return (
        <div key={key}>
          <Matching questionNode={question} questionNodeIndex={index} qtiModel={qtiModel} printView={3} />
        </div>
      );
    case CustomQuestionsService.FillInBlanks:
      return (
        <div key={key}>
          <FillInBlanks questionNode={question} questionNodeIndex={index} qtiModel={qtiModel} printView={3} />
        </div>
      );
    case CustomQuestionsService.Essay:
      return (
        <div key={key}>
          <Essay questionNode={question} questionNodeIndex={index} qtiModel={qtiModel} printView={3} />
        </div>
      );
    default:
      return null;
  }
};

export default DraggableQuestion;
