import { useState } from 'react';
import { useIntl } from 'react-intl';
import deepEqual from 'deep-equal';
import { Tree } from '@minoru/react-dnd-treeview';
import { useQuestionsContext } from '../../providers/Questions/QuestionsContext';
import NoDragWrapper from '../common/NoDragWrapper';
import Placeholder from './Placeholder';
import './YourQuestionsTreeView.css';
import { useAppContext } from '../../context/AppContext';

/**
 * YourQuestionsTreeView component
 *
 * @param {object} props - Component props
 * @param {object} props.selectedFolder - Selected folder
 * @param {function} props.onEditFolderNameClick - Callback for edit folder name click
 */
const YourQuestionsTreeView = ({ selectedFolder, onEditFolderNameClick }) => {
  const intl = useIntl();
  const { dispatchEvent } = useAppContext();
  const { treeDataAsArray, loadChildFoldersAndQuestions, handleQuestionDragAndDrop, handleFolderDragAndDrop } =
    useQuestionsContext();

  const [isDragging, setIsDragging] = useState();
  const [selectedQuestionTest, setSelectedQuestionTest] = useState();

  /**
   * Load child nodes for a given node
   *
   * @param {object} node - Node to load child nodes for
   */
  const loadChildNodes = node => {
    loadChildFoldersAndQuestions(node);
  };

  /**
   * Handle edit folder name click
   *
   * @param {object} node - Node to edit folder name for
   */
  const handleEditFolderName = node => {
    onEditFolderNameClick(node);
  };

  /**
   * Handle add question to test
   *
   * @param {object} node - Node to add question to test for
   */
  const handleAdd = node => {
    dispatchEvent('ADD_NEW_QUESTION_TO_TEST', node.data);
    setSelectedQuestionTest(node.id);
  };

  /**
   * Handles the drop event for a drag-and-drop operation.
   *
   * @param {object} newTree - The new tree structure after the drop.
   * @param {object} dragSource - The source item being dragged.
   * @param {object} dropTarget - The target item where the drag source is being dropped.
   */
  const handleDrop = async (newTree, { dragSource, dropTarget }) => {
    // Handle question drag-and-drop
    if (dragSource.isQuestion) {
      handleQuestionDragAndDrop(dragSource, dropTarget);
    } else {
      // Ignore when folder is dropped at same position
      if (dragSource?.id === dropTarget?.id) {
        return;
      }

      // Check if folder is being reordered within the same parent
      if (dragSource?.parent === dropTarget?.id) {
        const newOrder = newTree.filter(item => item.parent === dropTarget.id);
        const oldOrder = treeDataAsArray.filter(item => item.parent === dropTarget.id);
        if (deepEqual(newOrder, oldOrder)) {
          return null; // no change, do nothing
        }
      }

      // Handle folder drag-and-drop
      handleFolderDragAndDrop(newTree, dragSource, dropTarget);
    }
  };

  return (
    <div
      className={`your-questions-treeview treeview ${isDragging ? 'grabbing' : ''}`}
      onMouseDown={() => setIsDragging(true)}
      onMouseUp={() => setIsDragging(false)}
      style={{ marginBottom: '-47px' }}
    >
      <Tree
        tree={treeDataAsArray}
        sort={false}
        rootId={0}
        canDrag={node => !node.noDrag}
        canDrop={(_currentTree, { dropTarget }) => {
          return !dropTarget ? true : dropTarget.droppable;
        }}
        dropTargetOffset={5}
        onDrop={handleDrop}
        dragPreviewRender={monitorProps => <div className="custom-drag-preview">{monitorProps.item.text}</div>}
        onDragStart={() => setIsDragging(true)}
        onDragEnd={() => setIsDragging(false)}
        placeholderRender={(node, { depth }) => <Placeholder node={node} depth={depth} />}
        render={(node, { isOpen, onToggle }) => (
          <div
            className="question-folder-tree-node"
            tabIndex="0"
            aria-describedby=""
            // onKeyDown={() => fetchChildFolders(node)}
          >
            {node.droppable && (
              <NoDragWrapper node={node}>
                <span
                  onClick={() => {
                    if (!isOpen && !node.childrenPresent) {
                      loadChildNodes(node);
                    }
                    onToggle();
                  }}
                  className="caret-container"
                >
                  <i className={`fa ${isOpen ? 'fa-caret-down' : 'fa-caret-right'}`} tabIndex="0"></i>
                </span>
              </NoDragWrapper>
            )}
            <div className="tree-node-text">{node.text}</div>
            <NoDragWrapper node={node}>
              <div className="tree-node-action-buttons-container">
                {node.isQuestion ? (
                  <button
                    className={`action-button ${selectedQuestionTest === node.id ? 'selected' : ''}`}
                    onClick={() => handleAdd(node)}
                    title={intl.formatMessage({ id: 'addQuestionsIcon', defaultMessage: 'Add Questions' })}
                  >
                    <i className="bi bi-plus-lg darker-icon"></i>
                  </button>
                ) : (
                  <button
                    className={`action-button ${selectedFolder?.id === node.id ? 'selected' : ''}`}
                    onClick={() => handleEditFolderName(node)}
                    title={intl.formatMessage({ id: 'editButtonTitle', defaultMessage: 'Edit' })}
                  >
                    <i className="bi bi-pencil-fill"></i>
                  </button>
                )}
              </div>
            </NoDragWrapper>
          </div>
        )}
      />
    </div>
  );
};

export default YourQuestionsTreeView;
