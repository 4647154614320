import axios from 'axios';
import auth from '../services/Auth';

const httpInterceptor = axios.create({
  headers: {
    'x-requested-with': 'XMLHttpRequest',
  },
});

export default httpInterceptor;

httpInterceptor.interceptors.request.use(
  config => {
    if (auth.isTokenExpired()) {
      window.piSession.logout();
    }

    const token = auth.getToken();
    if (token) {
      config.headers['x-authorization'] = token;
    }
    return config;
  },
  error => {
    console.log('Failed to get active session');
    Promise.reject(error);
  }
);
