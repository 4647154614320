import { AtomicBlockUtils, EditorState, Modifier, RichUtils, SelectionState } from 'draft-js';
import { useEditorContext } from '../provider/EditorContext';
import InlineStyles from './InlineStyles';
import FontSizeList from './components/FontSizeList';
import ColorPickerPopup from './components/ColorPickerPopup';
import Alignment from './components/Alignment';
import UndoRedo from './components/UndoRedo';
import Indent from './components/Indent';
import ListType from './components/ListType';
import BackgroundColorPickerPopup from './components/BackgroundColorPickerPopup';
import FontFamilyList from './components/FontFamilyList';
import ImagePopup from './components/ImagePopup/ImagePopup';
import { MAX_INDENT_DEPTH, MAX_LIST_DEPTH } from '../utils/constants';
import './Toolbar.css';

/**
 * Move the selection to the start of the editor.
 *
 * @param {EditorState} currentEditorState - The current editor state.
 * @returns {EditorState} The updated editor state with the selection moved to the start.
 */
const moveSelectionToStart = currentEditorState => {
  let selection = currentEditorState.getSelection();
  const content = currentEditorState.getCurrentContent();
  const firstBlock = content.getFirstBlock();
  const key = firstBlock.getKey();
  selection = SelectionState.createEmpty(key);
  return EditorState.forceSelection(currentEditorState, selection);
};

/**
 * Set the selection if none is present.
 *
 * @param {EditorState} currentEditorState - The current editor state.
 * @returns {EditorState} The updated editor state with the selection set.
 */
const setSelectionIfNone = currentEditorState => {
  const selection = currentEditorState.getSelection();
  if (!selection.getHasFocus()) {
    return moveSelectionToStart(currentEditorState);
  }
  return currentEditorState;
};

/**
 * Toolbar component for the editor.
 */
const Toolbar = () => {
  const { activeEditorState, updateEditorState } = useEditorContext();

  /**
   * Handle toggle inline style.
   *
   * @param {string} inlineStyle - The inline style to toggle.
   */
  const handleToggleInlineStyle = inlineStyle => {
    if (activeEditorState) {
      let newEditorState = setSelectionIfNone(activeEditorState);

      // if the new style is a compound style type (fontFamily, fontSize, color, or backgroundColor) and the current style includes the same type,
      // remove the current matching style type before turning on the new style.
      const existingMatch = activeEditorState
        .getCurrentInlineStyle() // getCurrentInlineStyle() returns an Immutable OrderedSet
        .filter(style => style.includes('-') && style.split('-')[0] === inlineStyle.split('-')[0])
        .toList()
        .get(0);
      if (existingMatch) {
        newEditorState = RichUtils.toggleInlineStyle(newEditorState, existingMatch);
      }

      // activeEditorState.getCurrentInlineStyle().filter(style => {
      //   console.log('style::', style);
      //   return true;
      // });

      if (inlineStyle.endsWith('unset')) {
        updateEditorState(newEditorState);
      } else {
        updateEditorState(RichUtils.toggleInlineStyle(newEditorState, inlineStyle));
      }
    }
  };

  /**
   * Handle set alignment.
   *
   * @param {string} alignment - The alignment to set.
   */
  const handleSetAlignment = alignment => {
    if (activeEditorState) {
      updateEditorState(RichUtils.toggleBlockType(activeEditorState, alignment));
    }
  };

  /**
   * Handle undo/redo.
   *
   * @param {string} event - The event type (undo or redo).
   */
  const handleUndoRedo = event => {
    if (activeEditorState) {
      const newState = event === 'undo' ? EditorState.undo(activeEditorState) : EditorState.redo(activeEditorState);
      updateEditorState(newState);
    }
  };

  /**
   * Handle set indent.
   *
   * @param {string} direction - The direction to indent (INDENT or OUTDENT).
   */
  const handleSetIndent = direction => {
    if (activeEditorState) {
      const selectionState = activeEditorState.getSelection();
      const contentState = activeEditorState.getCurrentContent();
      const adjustment = direction === 'INDENT' ? 1 : -1;
      const startKey = selectionState.getStartKey();
      const endKey = selectionState.getEndKey();
      let blockMap = contentState.getBlockMap();
      const blocks = blockMap
        .toSeq()
        .skipUntil((_, k) => k === startKey)
        .takeUntil((_, k) => k === endKey)
        .concat([[endKey, blockMap.get(endKey)]])
        .map(block => {
          const depth = block.getDepth();
          const maxDepth = block.getType().includes('list-item') ? MAX_LIST_DEPTH : MAX_INDENT_DEPTH;
          const newDepth = Math.max(0, Math.min(depth + adjustment, maxDepth));
          return block.set('depth', newDepth);
        });

      blockMap = blockMap.merge(blocks);
      const newContentState = contentState.merge({
        blockMap,
        selectionBefore: selectionState,
        selectionAfter: selectionState,
      });
      const newEditorState = EditorState.push(activeEditorState, newContentState, 'adjust-depth');

      updateEditorState(newEditorState);
    }
  };

  /**
   * Toggle block type.
   *
   * @param {string} blockType - The block type to toggle.
   */
  const toggleBlockType = blockType => {
    if (activeEditorState) {
      updateEditorState(RichUtils.toggleBlockType(activeEditorState, blockType));
    }
  };

  /**
   * Function to add an image entity.
   *
   * @param {EditorState} editorState - The current editor state.
   * @param {Object} options - The options for the image entity.
   * @returns {EditorState} The updated editor state with the image entity.
   */
  const addImage = (editorState, options) => {
    const contentState = editorState.getCurrentContent();
    const contentStateWithEntity = contentState.createEntity('IMAGE', 'IMMUTABLE', options);
    const entityKey = contentStateWithEntity.getLastCreatedEntityKey();

    if (options.type === 'block') {
      const newEditorState = EditorState.set(editorState, { currentContent: contentStateWithEntity });
      return AtomicBlockUtils.insertAtomicBlock(newEditorState, entityKey, ' ');
    } else {
      const selectionState = editorState.getSelection();
      const contentStateWithText = Modifier.insertText(contentState, selectionState, ' ', null, entityKey);
      return EditorState.push(editorState, contentStateWithText, 'insert-characters');
    }
  };

  /**
   * Insert an image.
   *
   * @param {Object} options - The options for the image entity.
   */
  const insertImage = options => {
    if (activeEditorState) {
      const newEditorState = addImage(activeEditorState, options);
      updateEditorState(newEditorState);
    }
  };

  return (
    <div className="toolbar-container">
      <FontFamilyList onChange={handleToggleInlineStyle} />
      <FontSizeList onChange={handleToggleInlineStyle} />
      <InlineStyles />
      <ColorPickerPopup onChange={handleToggleInlineStyle} />
      <BackgroundColorPickerPopup onChange={handleToggleInlineStyle} />
      <Alignment onChange={handleSetAlignment} />
      <ListType onChange={toggleBlockType} />
      <Indent onChange={handleSetIndent} />
      <ImagePopup editorState={activeEditorState} onChange={insertImage} />
      <UndoRedo onChange={handleUndoRedo} />
    </div>
  );
};

export default Toolbar;
