import { useIntl } from 'react-intl';
import MultipleChoiceEditView from './edit-view/MultipleResponse/MultipleResponseEditView';
import { sanitizedData } from './utils/common';
import { useAppContext } from '../../context/AppContext';

const MultipleResponse = props => {
  const { questionNode, questionNodeIndex, onQuestionStateChange, onQuestionDelete } = props;
  const intl = useIntl();

  const caption = questionNode?.qtiModel?.Caption || '';
  const options = questionNode?.qtiModel?.Options || [];
  const correctAnswers = questionNode?.qtiModel?.CorrectAnswer || [];

  const handleEdit = e => {
    e.preventDefault();
    questionNode.qtiModel.EditOption = true;
    questionNode.qtiModel.EditAttempted = true;
    onQuestionStateChange(true);
  };

  const handleDelete = e => {
    e.preventDefault();
    if (questionNode.qtiModel.EditOption) {
      questionNode.qtiModel.EditOption = false;
    }
    onQuestionDelete(questionNodeIndex);
    onQuestionStateChange(false);
  };

  const getPrintOnlyView = () => {
    return (
      <div className="mb-1 d-flex align-items-center m-2 question-container">
        <div className="flex-grow-1 d-flex align-items-center ml-7 d-flex align-items-center flex-wrap">
          <div className={correctAnswers !== -1 ? 'w-100 ml-1' : 'w-100'}>
            <div className="mr-2 d-flex align-items-start gap-1">
              {questionNodeIndex + 1}
              <span className="view-content flex-grow-1" dangerouslySetInnerHTML={sanitizedData(caption)}></span>
            </div>
          </div>

          <div className="w-100" style={{ paddingTop: '15px' }}>
            {options.map((value, index) => {
              return (
                <div key={index} className="view-question">
                  <div className="icon-section">
                    <span className="icon-ml"></span>
                  </div>
                  <div className="text-section">
                    <span className="ml-1">{String.fromCharCode(index + 'A'.charCodeAt(0))})</span>
                    <span className="ml-1 answer flex-grow-1" dangerouslySetInnerHTML={sanitizedData(value)}></span>
                  </div>
                </div>
              );
            })}
          </div>
        </div>
      </div>
    );
  };

  const getPrintWithEditView = () => {
    return (
      <div className="mb-1 d-flex align-items-center m-2 question-container">
        <div className="flex-grow-1 d-flex align-items-center ml-7 d-flex align-items-center flex-wrap">
          <div className={correctAnswers !== -1 ? 'w-100 ml-1' : 'w-100'}>
            <div className="mr-2 d-flex align-items-start gap-1">
              {questionNodeIndex + 1})
              <span className="view-content flex-grow-1" dangerouslySetInnerHTML={sanitizedData(caption)}></span>
            </div>
          </div>

          <div className="w-100" style={{ paddingTop: '15px' }}>
            {options.map((value, index) => {
              return (
                <div key={index} className="view-question">
                  <div className={`text-section w-100 ${correctAnswers[index] ? 'checked' : ''}`}>
                    <div className="icon-section">
                      {correctAnswers[index] ? (
                        <i className="bi bi-check" style={{ color: 'green', marginRight: '2px' }}></i>
                      ) : (
                        <span className="icon-ml"></span>
                      )}
                    </div>
                    <span className="ml-1">{String.fromCharCode(index + 'A'.charCodeAt(0))})</span>
                    <span className="ml-1 answer flex-grow-1" dangerouslySetInnerHTML={sanitizedData(value)}></span>
                  </div>
                </div>
              );
            })}
          </div>
        </div>
        <div className="mr-7 d-flex align-items-center d-flex justify-content-end align-self-start">
          <button
            className="editbtn"
            onClick={handleEdit}
            title={intl.formatMessage({ id: 'editButtonTitle', defaultMessage: 'Edit' })}
          >
            <i className="bi bi-pencil-fill"></i>
          </button>
          <button
            className="deletebtn"
            onClick={handleDelete}
            title={intl.formatMessage({ id: 'deleteButtonTitle', defaultMessage: 'Remove' })}
          >
            <i className="bi bi-trash"></i>
          </button>
        </div>
      </div>
    );
  };

  const getPrintWithAnswerView = () => {
    return (
      <div className="mb-1 d-flex align-items-center m-2 question-container">
        <div className="flex-grow-1 d-flex align-items-center ml-7 d-flex align-items-center flex-wrap">
          <div className={correctAnswers !== -1 ? 'w-100 ml-1' : 'w-100'}>
            <div className="mr-2 d-flex align-items-start gap-1">
              {questionNodeIndex + 1})
              <span className="view-conten flex-grow-1t" dangerouslySetInnerHTML={sanitizedData(caption)}></span>
            </div>
          </div>

          <div className="w-100" style={{ paddingTop: '15px' }}>
            {options.map((value, index) => {
              return (
                <div key={index} className="view-question">
                  <div className={`text-section w-100 ${correctAnswers[index] ? 'checked' : ''}`}>
                    <div className="icon-section">
                      {correctAnswers[index] ? (
                        <i className="bi bi-check" style={{ color: 'green', marginRight: '2px' }}></i>
                      ) : (
                        <span className="icon-ml"></span>
                      )}
                    </div>
                    <span className="ml-1">{String.fromCharCode(index + 'A'.charCodeAt(0))})</span>
                    <span className="ml-1 answer flex-grow-1" dangerouslySetInnerHTML={sanitizedData(value)}></span>
                  </div>
                </div>
              );
            })}
          </div>
        </div>
      </div>
    );
  };

  const getPrintView = viewId => {
    if (viewId == 3) {
      return getPrintWithAnswerView();
    } else if (viewId == 2) {
      return getPrintWithEditView();
    } else {
      return getPrintOnlyView();
    }
  };

  return (
    <div id={questionNode.itemId}>
      {!questionNode.qtiModel.EditOption ? getPrintView(props.printView) : <MultipleChoiceEditView {...props} />}
    </div>
  );
};

export default MultipleResponse;
