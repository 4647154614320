import React, { useEffect, useState } from 'react';
import { Tree } from '@minoru/react-dnd-treeview';
import { useIntl } from 'react-intl';

import DraggableNode from './DraggableNode';
import { toastify } from '../../../components/common/Toastify';
import { getChaptersByBookID } from '../../../services/book.service';
import { useAppContext } from '../../../context/AppContext';
import { generateChapters, loadQuestions } from './utils';

function AdvancedSearchTreeView({ lastSelectedQuestion, handleAdd }) {
  const intl = useIntl();
  const {
    questionBanksData: { advancedSearchSelection, selectedBookNodes, selectedBookIds },
    dispatchEvent,
  } = useAppContext();
  const [filteredTreeData, setFilteredTreeData] = useState([]);
  const [initialOpen, setInitialOpen] = useState([]);
  const [loadedChaprters, setLoadedChapters] = useState(new Set());

  useEffect(() => {
    if (advancedSearchSelection && advancedSearchSelection.length && selectedBookIds.length) {
      setLoadedChapters(new Set());
      loadChaptersUnderBooks();
    }
  }, [advancedSearchSelection, selectedBookIds]);

  /**
   * Loads chapters under the selected books.
   */
  const loadChaptersUnderBooks = async () => {
    dispatchEvent('SHOW_LOADER');
    const books = selectedBookNodes.filter(node => selectedBookIds.includes(node.id));
    try {
      // Fetch chapters for the selected books
      const chaptersForBooks = await Promise.allSettled(
        books.map(book => getChaptersByBookID(book.bookGuid, advancedSearchSelection))
      );

      // Flatten the chapters array
      const flattenedChapters = chaptersForBooks.reduce((acc, chapters, index) => {
        if (chapters.status === 'fulfilled') {
          return acc.concat(generateChapters(chapters.value, books[index]));
        }

        return acc;
      }, []);

      if (flattenedChapters.length === 0) {
        toastify.showWarningToast(intl.formatMessage({ id: 'warning.noContentFound' }));
      }

      // Filter the selected book nodes based on the discipline type
      const disciplines = selectedBookNodes.filter(
        node => node.type === 'discipline' && books.some(book => book.parent === node.id)
      );

      // Set the filtered tree data and search term presence
      setFilteredTreeData([...disciplines, ...books, ...flattenedChapters]);
      setInitialOpen([...disciplines.map(node => node.id)]);
    } catch (error) {
      toastify.showErrorToast(error);
    } finally {
      dispatchEvent('HIDE_LOADER');
    }
  };

  const handleNodeClick = async clickedNode => {
    const isChapter = clickedNode.type !== 'discipline' && clickedNode.type !== 'book';

    if (clickedNode.droppable && isChapter && !loadedChaprters.has(clickedNode.id)) {
      dispatchEvent('SHOW_LOADER');
      const questions = await loadQuestions(clickedNode, advancedSearchSelection);

      setLoadedChapters(chapeters => chapeters.add(clickedNode.id));
      setFilteredTreeData(filteredTreeData => [...filteredTreeData, ...questions]);
      dispatchEvent('HIDE_LOADER');
    }
  };

  return (
    <div className="question-banks-treeview">
      <Tree
        tree={filteredTreeData}
        rootId={0}
        render={(node, { isOpen, onToggle }) => (
          <DraggableNode
            key={node.id}
            node={node}
            isOpen={isOpen}
            onToggle={onToggle}
            onDataUpdate={handleNodeClick}
            isClicked={selectedBookIds.includes(node.id)}
            lastSelectedQuestion={lastSelectedQuestion}
            handleAdd={handleAdd}
          />
        )}
        initialOpen={initialOpen}
      />
    </div>
  );
}

export default AdvancedSearchTreeView;
