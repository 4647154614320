import React, { Suspense } from 'react';
import { createRoot } from 'react-dom/client';
import { HashRouter } from 'react-router-dom';
import { RawIntlProvider } from 'react-intl';
import App from './App';
import reportWebVitals from './reportWebVitals';
import Loader from './components/common/loader/Loader';
import { intl } from './utils/localization/Localization';

import { AuthProvider } from './context/AuthContext';
import { AppProvider } from './context/AppContext';

// Add FontAwesome styles
import '@fortawesome/fontawesome-free/css/all.min.css';
import 'bootstrap/dist/css/bootstrap.min.css';
import 'react-toastify/dist/ReactToastify.css';
import './index.css';
import QuestionsProvider from './providers/Questions/QuestionsProvider';

const root = createRoot(document.getElementById('root'));

root.render(
  <Suspense fallback={<Loader />}>
    <HashRouter>
      <RawIntlProvider value={intl}>
        <AuthProvider>
          <AppProvider>
            <QuestionsProvider>
              {/* <React.StrictMode> */}
              <App />
              {/* </React.StrictMode> */}
            </QuestionsProvider>
          </AppProvider>
        </AuthProvider>
      </RawIntlProvider>
    </HashRouter>
  </Suspense>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
