// authService.js

import httpInterceptor from '../httpHelper/httpHelper';

const apiUrl = `${process.env.REACT_APP_API_URL}/auth`; // Assuming you have your API URL defined in environment variables

const callLoginEndpoint = async token => {
  try {
    const config = {
      headers: {
        AccessToken: token,
        UserId: sessionStorage.getItem('userId'),
        SmsUserId: sessionStorage.getItem('SmsUserId'),
        Accept: 'application/json',
      },
    };

    const response = await httpInterceptor.get(apiUrl, config);
    return response.data;
  } catch (error) {
    console.error('Login Error:', error);
    throw error;
  }
};

export default callLoginEndpoint;
