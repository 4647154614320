import React, { useState } from 'react';
import { Button, Col, Collapse, Form, Row } from 'react-bootstrap';
import DOMPurify from 'dompurify';
import { FormattedMessage, useIntl } from 'react-intl';
import CustomQuestionsService from '../../services/CustomQuestionsService';
import QtiService from '../../utils/qti-converter';
import { useAppContext } from '../../context/AppContext';
import { METADATA_OPTIONS } from '../../config/metadata-constants';
import { toastify } from '../common/Toastify';
import { EditorProvider, Editor, Toolbar } from '../lib/rte';

const MultipleChoice = props => {
  const intl = useIntl();
  const [open, setOpen] = useState(false);
  const { userMetadata } = useAppContext();
  const questionNode = props.questionNode;
  const questionNodeIndex = props.questionNodeIndex;
  const initFormData = {
    Caption: questionNode.qtiModel ? questionNode.qtiModel.Caption : '',
    Options: questionNode.qtiModel ? questionNode.qtiModel.Options : ['', '', '', ''],
    CorrectAnswer: questionNode.qtiModel ? questionNode.qtiModel.CorrectAnswer : -1,
    Orientation: questionNode.qtiModel ? String(questionNode.qtiModel.Orientation) : 'false',
    questionId: questionNode ? questionNode?.extendedMetadata?.questionId : '',
    pageReference: questionNode ? questionNode?.extendedMetadata?.pageReference : '',
    topic: questionNode ? questionNode?.extendedMetadata?.topic : '',
    skill: questionNode ? questionNode?.extendedMetadata?.skill : '',
    objective: questionNode ? questionNode?.extendedMetadata?.objective : '',
    difficulty: questionNode ? questionNode?.extendedMetadata?.difficulty : '',
  };
  const optionsCount = process.env.REACT_APP_ANSWER_OPTIONS_COUNT;

  if (Array.isArray(questionNode.extendedMetadata)) {
    questionNode.extendedMetadata.forEach(metadata => {
      initFormData[metadata.name] = metadata.value;
    });
  }
  const [formData, setFormData] = useState(initFormData);

  const handleChange = e => {
    const { name, value } = e.target || e;
    setFormData({ ...formData, [name]: value });
  };

  const handleOptionsChange = e => {
    const { name, value } = e.target || e;
    //copying data to temp variable so that we do not directly mutate original state
    const tempOptions = [...formData.Options];
    //findIndex to find location of item we need to update
    tempOptions[name] = value;
    setFormData({ ...formData, Options: tempOptions });
  };

  const handleDropdownChange = e => {
    const { name, value } = e.target;
    setFormData(prev => ({ ...prev, [name]: value }));
  };

  const handleSubmit = e => {
    console.log('formdata', formData);
    e.preventDefault();
    if (questionNode) {
      questionNode.qtiModel.Caption = formData.Caption;
      questionNode.qtiModel.Options = formData.Options;
      questionNode.qtiModel.CorrectAnswer = formData.CorrectAnswer;
      questionNode.qtiModel.Orientation = formData.Orientation;
      questionNode.qtiModel.EditOption = false;
      questionNode.extendedMetadata = {
        questionId: formData.questionId,
        pageReference: formData.pageReference,
        topic: formData.topic,
        skill: formData.skill,
        objective: formData.objective,
        difficulty: formData.difficulty,
      };
      let jsonToXML = QtiService.getQtiXML(questionNode);
      questionNode.data = jsonToXML;
      const questionTemplates = CustomQuestionsService.questionTemplates(questionNode);

      let xmlToHtml = questionTemplates[0].textHTML;

      const testObj = { ...props.selectedTest }; // Create a copy of selectedTest

      // Find if any question in the array has the same itemId
      const existingQuestion = testObj.questions.find(q => q.itemId === questionNode.itemId);

      if (existingQuestion) {
        // If the question doesn't exist, add it to the end of the array
        questionNode.spaceLine = formData.spaceLine || 0;
        questionNode.textHTML = xmlToHtml;
      } else {
        // If the question already exists, update it
        testObj.questions[props.questionNodeIndex] = {
          ...testObj.questions[props.questionNodeIndex],
          spaceLine: formData.spaceLine || 0,
          textHTML: xmlToHtml,
        };
      }

      // // Find if any question in the array has the same itemId
      // const existingQuestionIndex = testObj.questions.findIndex(q => q.itemId === questionNode.itemId);

      // if (existingQuestionIndex !== -1) {
      //   // If the question already exists, update it
      //   testObj.questions[existingQuestionIndex] = {
      //     ...testObj.questions[existingQuestionIndex],
      //     spaceLine: formData.spaceLine || 0,
      //     textHTML: xmlToHtml,
      //   };
      // } else {
      //   // If the question doesn't exist, add it to the end of the array
      //   testObj.questions.push({
      //     ...questionNode,
      //     spaceLine: formData.spaceLine || 0,
      //     textHTML: xmlToHtml,
      //   });
      // }

      // Update the selected test with the modified questions array
      props.setSelectedTest(testObj);
    }
    props.onQuestionStateChange(false);
  };

  const handleEdit = e => {
    e.preventDefault();
    questionNode.qtiModel.EditOption = true;
    questionNode.qtiModel.EditAttempted = true;
    props.onQuestionStateChange(true);
  };

  const handleDelete = e => {
    e.preventDefault();
    if (questionNode.qtiModel.EditOption) {
      questionNode.qtiModel.EditOption = false;
      props.onQuestionDelete(questionNodeIndex);
      props.onQuestionStateChange(false);
    } else {
      props.onQuestionDelete(questionNodeIndex);
      props.onQuestionStateChange(false);
    }
  };

  const sanitizedData = data => ({
    __html: DOMPurify.sanitize(data),
  });

  const handleAddOption = () => {
    if (formData.Options.length < optionsCount) {
      setFormData(prev => ({
        ...prev,
        Options: [...prev.Options, ''],
      }));
    } else {
      let msg = intl.formatMessage({ id: 'warning.moreoptions' });
      msg = msg.replace('{$}', optionsCount);
      toastify.showWarningToast(msg);
    }
  };

  const handleRemoveOption = index => {
    if (formData.Options.length > 2) {
      let newCorrectAnswer = formData.CorrectAnswer;
      if (index === formData.CorrectAnswer) {
        newCorrectAnswer = -1;
      } else if (index < formData.CorrectAnswer) {
        newCorrectAnswer = formData.CorrectAnswer - 1;
      }

      setFormData(prev => ({
        ...prev,
        Options: prev.Options.filter((opt, i) => i !== index),
        CorrectAnswer: newCorrectAnswer,
      }));
    }
  };
  const handleKeyDown = event => {
    if (event.key === 'Enter' || event.key === ' ') {
      event.preventDefault();
      setOpen(!open);
    }
  };
  const getEditView = () => {
    const isCaptionFilled = formData.Caption.trim() !== '';
    const areOptionsFilled = formData.Options.every(opt => opt && opt.trim() !== '');
    const isViewButtonEnabled = isCaptionFilled && areOptionsFilled;
    return (
      <div className="m-2 border rounded p-3 bg-light">
        <EditorProvider>
          <Toolbar />
          <Form className="editmode paddingright10">
            <Form.Group className="mb-3" controlId="exampleForm.ControlInput1">
              <Form.Label className="mb-1" tabIndex="0">
                <b>{props.questionNode.qtiModel.QstnSectionTitle}</b>
              </Form.Label>
              {/* <Form.Control
              name="Caption"
              onChange={handleChange}
              value={formData.Caption}
              className="mb-2"
              type="text"
              autoComplete="off"
              placeholder={props.questionNode.qtiModel.EditCaption}
            /> */}
              <Editor
                name="Caption"
                value={formData.Caption}
                className="mb-2"
                placeholder={props.questionNode.qtiModel.EditCaption}
                onChange={value => handleChange({ name: 'Caption', value })}
              />
              <Form.Group className="mb-1 mt-3 d-flex flex-wrap">
                {formData?.Options?.length > 0 &&
                  formData?.Options.map((optItem, index) => {
                    return (
                      <Form.Group key={index} className="mc-flex-row mb-2">
                        <div className="mc-col mc-col-1" tabIndex="0">
                          <Form.Check
                            type="radio"
                            checked={index === formData.CorrectAnswer}
                            value={index}
                            name="CorrectAnswer"
                            className="item-1"
                            onChange={e => setFormData({ ...formData, CorrectAnswer: index })}
                          />
                        </div>
                        <div className="mc-col mc-col-2">
                          <Editor
                            key={`editor-${index}-${formData.Options.length}`}
                            name={index}
                            value={optItem}
                            className="item-2"
                            placeholder="Enter Answer"
                            onChange={value => handleOptionsChange({ name: index, value })}
                          />
                        </div>
                        <Button
                          className="opt-close-button highlight-icon-button"
                          title={intl.formatMessage({
                            id: 'cancelButtonforOptions',
                            defaultMessage: 'Delete Option',
                          })}
                          variant="link"
                          onClick={() => handleRemoveOption(index)}
                          disabled={index == formData.CorrectAnswer || formData.Options.length <= 2}
                        >
                          <i className="bi bi-x-lg"></i>
                        </Button>
                      </Form.Group>
                    );
                  })}
              </Form.Group>
              <div className="d-flex justify-content-end">
                <Button onClick={handleAddOption}>
                  <FormattedMessage id="addOptionsforMultiplechoice" />
                </Button>
              </div>
            </Form.Group>

            <div className="d-flex align-items-center mb-3">
              <div className="pointer" onClick={() => setOpen(!open)} onKeyDown={handleKeyDown} tabIndex="0">
                {open ? <i className="bi bi-caret-down-fill"></i> : <i className="bi bi-caret-right-fill"></i>}
              </div>
              <span className="ms-2" tabIndex="0">
                <b>
                  <FormattedMessage id="message.formatAndAddMetadata" />
                </b>
              </span>
            </div>
            <Collapse key={open ? 'open' : 'closed'} in={open}>
              <div className="metadata-container">
                <div className="mb-2">
                  <span className="caption-metadata">
                    <FormattedMessage id="formatMetadataMultipleChoice1" />
                  </span>
                  <span className="normal-text">
                    <FormattedMessage id="visibleInPrintView" />
                  </span>
                </div>
                <div className="d-flex mc--orientation">
                  <Form.Check
                    type="radio"
                    onChange={handleChange}
                    checked={'false' == formData.Orientation}
                    className="mr-5"
                    name="Orientation"
                    value="false"
                    label="Horizontal Display"
                    tabIndex="0"
                  />

                  <Form.Check
                    type="radio"
                    onChange={handleChange}
                    checked={'true' == formData.Orientation}
                    className=""
                    name="Orientation"
                    value="true"
                    label="Vertical Display"
                    tabIndex="0"
                  />
                </div>
                <div className="mt-3 metadata-fields-container">
                  <FormattedMessage id="message.metadataTitle" />
                  <Row className="m-2">
                    <Col xs={12}>
                      {userMetadata.includes(METADATA_OPTIONS.QUESTION_ID) && (
                        <Form.Group controlId="formGridID" className="mt-2 d-flex align-items-center">
                          <Form.Label>
                            <FormattedMessage id="questionIdLabel" />
                          </Form.Label>
                          <Form.Control
                            aria-label="Question ID"
                            type="text"
                            placeholder=""
                            className=""
                            name={METADATA_OPTIONS.QUESTION_ID}
                            value={formData.questionId}
                            onChange={handleChange}
                          />
                        </Form.Group>
                      )}
                      {userMetadata.includes(METADATA_OPTIONS.DIFFICULTY) && (
                        <Form.Group className="mt-2 d-flex align-items-center">
                          <Form.Label>
                            <FormattedMessage id="difficultyLabel" />
                          </Form.Label>
                          <Form.Select
                            aria-label="difficultyLabel"
                            className="difficultyLabelSelect"
                            size="md"
                            name={METADATA_OPTIONS.DIFFICULTY}
                            value={formData.difficulty}
                            onChange={handleDropdownChange}
                          >
                            <option value="" className="difficultyLabelOptional">
                              <FormattedMessage id="chooseLevel" />
                            </option>
                            <option value="Easy" className="difficultyLabelOptional">
                              <FormattedMessage id="difficultyEasy" />
                            </option>
                            <option value="Moderate" className="difficultyLabelOptional">
                              <FormattedMessage id="difficultyMedium" />
                            </option>
                            <option value="Difficult" className="difficultyLabelOptional">
                              <FormattedMessage id="difficultyHard" />
                            </option>
                          </Form.Select>
                        </Form.Group>
                      )}
                      {userMetadata.includes(METADATA_OPTIONS.PAGE_REFERENCE) && (
                        <Form.Group className="mt-2 d-flex align-items-center">
                          <Form.Label>
                            <FormattedMessage id="pageReferenceLabel" />
                          </Form.Label>
                          <Form.Control
                            aria-label="pageReferenceLabel"
                            type="text"
                            placeholder=""
                            className=""
                            name={METADATA_OPTIONS.PAGE_REFERENCE}
                            value={formData.pageReference}
                            onChange={handleChange}
                          />
                        </Form.Group>
                      )}
                      {userMetadata.includes(METADATA_OPTIONS.TOPIC) && (
                        <Form.Group className="mt-2 d-flex align-items-center">
                          <Form.Label>
                            <FormattedMessage id="topicLabel" />
                          </Form.Label>
                          <Form.Control
                            aria-label="topicLabel"
                            type="text"
                            placeholder=""
                            className=""
                            name={METADATA_OPTIONS.TOPIC}
                            value={formData.topic}
                            onChange={handleChange}
                          />
                        </Form.Group>
                      )}
                      {userMetadata.includes(METADATA_OPTIONS.SKILL) && (
                        <Form.Group className="mt-2 d-flex align-items-center">
                          <Form.Label>
                            <FormattedMessage id="skillLabel" />
                          </Form.Label>
                          <Form.Control
                            aria-label="skillLabel"
                            type="text"
                            placeholder=""
                            className=""
                            name={METADATA_OPTIONS.SKILL}
                            value={formData.skill}
                            onChange={handleChange}
                          />
                        </Form.Group>
                      )}
                      {userMetadata.includes(METADATA_OPTIONS.OBJECTIVE) && (
                        <Form.Group className="mt-2 d-flex align-items-center">
                          <Form.Label>
                            <FormattedMessage id="objectiveLabel" />
                          </Form.Label>
                          <Form.Control
                            aria-label="objectiveLabel"
                            type="text"
                            placeholder=""
                            className=""
                            name={METADATA_OPTIONS.OBJECTIVE}
                            value={formData.objective}
                            onChange={handleChange}
                          />
                        </Form.Group>
                      )}
                    </Col>
                  </Row>
                </div>
              </div>
            </Collapse>
          </Form>
          <div className="mb-1 d-flex justify-content-end">
            <div
              className="tooltip-wrapper"
              title={intl.formatMessage({ id: 'viewButtonTitle', defaultMessage: 'View' })}
              tabIndex="0"
            >
              <button
                className={`savelink ${!isViewButtonEnabled ? 'disabled-link' : ''}`}
                onClick={handleSubmit}
                tabIndex={!isViewButtonEnabled ? -1 : 0}
                disabled={!isViewButtonEnabled}
                aria-label="view"
              >
                <i className="bi bi-eye"></i>
              </button>
            </div>
            <button
              className="deletelink"
              onClick={handleDelete}
              title={intl.formatMessage({ id: 'deleteButtonTitle', defaultMessage: 'Remove' })}
            >
              <i className="bi bi-trash"></i>
            </button>
          </div>
        </EditorProvider>
      </div>
    );
  };

  const getPrintOnlyView = () => {
    return (
      <div className="mb-3 d-flex align-items-center m-2 question-container">
        <div className="flex-grow-1 d-flex align-items-center ml-7 d-flex align-items-center flex-wrap">
          <div className={formData.CorrectAnswer !== -1 ? 'w-100 ml-1' : 'w-100'}>
            <div className="mr-2 d-flex align-items-start gap-1">
              {questionNodeIndex + 1})
              <span
                className="view-content flex-grow-1"
                dangerouslySetInnerHTML={sanitizedData(formData.Caption)}
              ></span>
            </div>
          </div>

          <div className="w-100 mt-3">
            {formData.Options.map((value, index) => {
              return (
                <div className="view-question">
                  <div className="icon-section">
                    <span className="icon-ml"></span>
                  </div>
                  <div className="text-section">
                    <span className="ml-1">{String.fromCharCode(index + 'A'.charCodeAt(0))})</span>
                    <span className="ml-1 answer flex-grow-1" dangerouslySetInnerHTML={sanitizedData(value)}></span>
                  </div>
                </div>
              );
            })}
          </div>
        </div>
      </div>
    );
  };

  const getPrintWithEditView = () => {
    return (
      <div className="mb-3 d-flex align-items-center m-2 question-container">
        <div className="flex-grow-1 d-flex align-items-center ml-7 d-flex align-items-center flex-wrap">
          <div className={formData.CorrectAnswer !== -1 ? 'w-100 ml-1' : 'w-100'}>
            <div className="mr-2 d-flex align-items-start gap-1">
              {questionNodeIndex + 1})
              <span
                className="view-content flex-grow-1"
                dangerouslySetInnerHTML={sanitizedData(formData.Caption)}
              ></span>
            </div>
          </div>

          <div className="w-100 mt-3">
            {formData.Options.map((value, index) => {
              return (
                <div className="view-question">
                  <div className={`text-section w-100 ${formData.CorrectAnswer == index ? 'checked' : ''}`}>
                    <div className="icon-section">
                      {formData.CorrectAnswer == index ? (
                        <i className="bi bi-check" style={{ color: 'green' }}></i>
                      ) : (
                        <span className="icon-ml"></span>
                      )}
                    </div>
                    <span className="ml-1">{String.fromCharCode(index + 'A'.charCodeAt(0))})</span>
                    <span className="ml-1 answer flex-grow-1" dangerouslySetInnerHTML={sanitizedData(value)}></span>
                  </div>
                </div>
              );
            })}
          </div>
        </div>
        <div className="mr-7 d-flex align-items-center d-flex justify-content-end align-self-start">
          <button
            className="editbtn"
            onClick={handleEdit}
            title={intl.formatMessage({ id: 'editButtonTitle', defaultMessage: 'Edit' })}
          >
            <i className="bi bi-pencil-fill"></i>
          </button>
          <button
            className="deletebtn"
            onClick={handleDelete}
            title={intl.formatMessage({ id: 'deleteButtonTitle', defaultMessage: 'Remove' })}
          >
            <i className="bi bi-trash"></i>
          </button>
        </div>
      </div>
    );
  };

  const getPrintWithAnswerView = () => {
    return (
      <div className="mb-3 d-flex align-items-center m-2 question-container">
        <div className="flex-grow-1 d-flex align-items-center ml-7 d-flex align-items-center flex-wrap">
          <div className={formData.CorrectAnswer !== -1 ? 'w-100 ml-1' : 'w-100'}>
            <div className="mr-2 d-flex align-items-start gap-1">
              {questionNodeIndex + 1})
              <span
                className="view-content flex-grow-1"
                dangerouslySetInnerHTML={sanitizedData(formData.Caption)}
              ></span>
            </div>
          </div>

          <div className="w-100 mt-3">
            {formData.Options.map((value, index) => {
              return (
                <div className="view-question">
                  <div className={`text-section w-100 ${formData.CorrectAnswer == index ? 'checked' : ''}`}>
                    <div className="icon-section">
                      {formData.CorrectAnswer == index ? (
                        <i className="bi bi-check" style={{ color: 'green' }}></i>
                      ) : (
                        <span className="icon-ml"></span>
                      )}
                    </div>
                    <span className="ml-1">{String.fromCharCode(index + 'A'.charCodeAt(0))})</span>
                    <span className="ml-1 answer flex-grow-1" dangerouslySetInnerHTML={sanitizedData(value)}></span>
                  </div>
                </div>
              );
            })}
          </div>
        </div>
      </div>
    );
  };

  const getPrintView = viewId => {
    if (viewId == 3) {
      return getPrintWithAnswerView();
    } else if (viewId == 2) {
      return getPrintWithEditView();
    } else {
      return getPrintOnlyView();
    }
  };

  return (
    <div id={questionNode.itemId}>
      {!questionNode.qtiModel.EditOption ? getPrintView(props.printView) : getEditView()}
    </div>
  );
};
export default MultipleChoice;
