import { useEffect } from 'react';
import AppRoutes from './routes/Router';
import { useAuth } from './context/AuthContext';
import { ToastContainer } from 'react-toastify';
import { DndProvider } from 'react-dnd';
import { MultiBackend, getBackendOptions } from '@minoru/react-dnd-treeview';
import { useAppContext } from './context/AppContext';
import Loader from './components/common/loader/Loader';
import ApplicationLoader from './components/common/ApplicationLoader';
import './styles/App.css';

function App() {
  const { isAuthFetching } = useAuth();
  const { dispatchEvent } = useAppContext();

  useEffect(() => {
    if (!isAuthFetching) {
      dispatchEvent('FETCH_USER_METADATA');
    }
  }, [isAuthFetching]);

  return (
    <>
      <DndProvider backend={MultiBackend} options={getBackendOptions()}>
        {isAuthFetching ? (
          <Loader show={true} />
        ) : (
          <div className="App">
            <div>{AppRoutes}</div>
            <ApplicationLoader />
          </div>
        )}
      </DndProvider>
      <ToastContainer />
    </>
  );
}

export default App;
