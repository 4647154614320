import { useEffect } from 'react';
import QuestionTemplatesPage from '../pages/question-templates-page/QuestionTemplatesPage.js';

const CustomQuestionsPage = () => {
  useEffect(() => {
    document.title = 'Question Template';
  }, []);

  return (
    <div>
      <QuestionTemplatesPage></QuestionTemplatesPage>
    </div>
  );
};
export default CustomQuestionsPage;
