import React, { useEffect } from 'react';
import './QuestionTemplatesPage.css';
import CustomQuestionsService from '../../services/CustomQuestionsService';
import { useDrag } from 'react-dnd';
import { useAppContext } from '../../context/AppContext';
import { getQuestionFromDto, transformQuestionTemplateToQuestion } from '../../utils/questions-utils';
import { FormattedMessage, useIntl } from 'react-intl';

const DraggableQuestionTemplate = ({ questionTemplate, onAddQuestion }) => {
  const intl = useIntl();
  const [, drag] = useDrag({
    type: 'QUESTION_TEMPLATE',
    item: { questionTemplate },
  });

  function getQuestionHtmlTemplate(questionTemplate) {
    return {
      __html: questionTemplate.textHTML,
    };
  }

  const handleQuestion = () => {
    const item = { questionTemplate };
    onAddQuestion(item);
  };

  return (
    <li className="question-item">
      <button
        className="question-templates-plus-icon"
        onClick={handleQuestion}
        title={intl.formatMessage({
          id: 'addQuestionTemplateTitle',
          defaultMessage: 'Add Question',
        })}
      >
        <i className="bi bi-plus-lg darker-icon"></i>
      </button>
      <div
        className="question-container"
        ref={drag}
        dangerouslySetInnerHTML={getQuestionHtmlTemplate(questionTemplate)}
      ></div>
    </li>
  );
};

const QuestionTemplatesPage = () => {
  const { dispatchEvent } = useAppContext();
  const questionTemplates = CustomQuestionsService.questionTemplates();

  const handleAddQuestion = item => {
    try {
      console.log('Dropped node:', item);
      let copyItem = JSON.parse(JSON.stringify(item));
      let newQuestion;
      if (item.type === 'QUESTION_TEMPLATE') {
        newQuestion = transformQuestionTemplateToQuestion(copyItem.questionTemplate);
      } else if (item.type === 'TREE_NODE') {
        newQuestion = transformQuestionTemplateToQuestion(copyItem.questionTemplate);
      } else if (item.question) {
        newQuestion = getQuestionFromDto(copyItem.question);
      } else {
        newQuestion = transformQuestionTemplateToQuestion(copyItem.questionTemplate);
      }

      dispatchEvent('ADD_NEW_QUESTION_TO_TEST', newQuestion);
    } catch (error) {
      console.error('Error adding question:', error);
    }
  };

  return (
    <div className="custom-questions-tab p-2" id="custom-ques">
      <ul className="question-template-layout">
        {questionTemplates.map((questionTemplate, index) => (
          <DraggableQuestionTemplate
            key={index}
            questionTemplate={questionTemplate}
            onAddQuestion={handleAddQuestion}
          />
        ))}
      </ul>
    </div>
  );
};

export default QuestionTemplatesPage;
