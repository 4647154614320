import { useDrag } from 'react-dnd';
import CustomQuestionsService from '../../../services/CustomQuestionsService';
import MultipleChoice from '../../../components/questions/MultipleChoice';
import MultipleResponse from '../../../components/questions/MultipleResponse';
import TrueFalse from '../../../components/questions/TrueFalse';
import Matching from '../../../components/questions/Matching';
import FillInBlanks from '../../../components/questions/FillInBlanks';
import Essay from '../../../components/questions/Essay';
import { FormattedMessage, useIntl } from 'react-intl';

const DraggableQuestion = ({ question, index, selectedQuestionId, handleAdd }) => {
  const intl = useIntl();
  const [, drag] = useDrag({
    type: 'SAVED_QUESTION',
    item: { question },
  });

  const key = question.guid;
  const { qtiModel } = question;

  switch (question.metadata.quizType) {
    case CustomQuestionsService.MultipleChoice:
      return (
        <div className="questionblock w-100" key={key} ref={drag}>
          <button
            className={`questionaddforquestionbank ${selectedQuestionId === question.guid ? 'selected' : ''}`}
            onClick={() => handleAdd(question)}
            title={intl.formatMessage({ id: 'addQuestionsIcon', defaultMessage: 'Add Questions' })}
          >
            <i className="bi bi-plus-lg darker-icon"></i>
          </button>
          <MultipleChoice questionNode={question} questionNodeIndex={index} qtiModel={qtiModel} printView={3} />
        </div>
      );
    case CustomQuestionsService.MultipleResponse:
      return (
        <div className="questionblock w-100" key={key} ref={drag}>
          <button
            className={`questionaddforquestionbank ${selectedQuestionId === question.guid ? 'selected' : ''}`}
            onClick={() => handleAdd(question)}
            title={intl.formatMessage({ id: 'addQuestionsIcon', defaultMessage: 'Add Questions' })}
          >
            <i className="bi bi-plus-lg darker-icon"></i>
          </button>
          <MultipleResponse questionNode={question} questionNodeIndex={index} qtiModel={qtiModel} printView={3} />
        </div>
      );
    case CustomQuestionsService.TrueFalse:
      return (
        <div className="questionblock w-100" key={key} ref={drag}>
          <button
            className={`questionaddforquestionbank ${selectedQuestionId === question.guid ? 'selected' : ''}`}
            onClick={() => handleAdd(question)}
            title={intl.formatMessage({ id: 'addQuestionsIcon', defaultMessage: 'Add Questions' })}
          >
            <i className="bi bi-plus-lg darker-icon"></i>
          </button>
          <TrueFalse questionNode={question} questionNodeIndex={index} qtiModel={qtiModel} printView={3} />
        </div>
      );
    case CustomQuestionsService.Matching:
      return (
        <div className="questionblock w-100" key={key} ref={drag}>
          <button
            className={`questionaddforquestionbank ${selectedQuestionId === question.guid ? 'selected' : ''}`}
            onClick={() => handleAdd(question)}
            title={intl.formatMessage({ id: 'addQuestionsIcon', defaultMessage: 'Add Questions' })}
          >
            <i className="bi bi-plus-lg darker-icon"></i>
          </button>
          <Matching questionNode={question} questionNodeIndex={index} qtiModel={qtiModel} printView={3} />
        </div>
      );
    case CustomQuestionsService.FillInBlanks:
      return (
        <div className="questionblock w-100" key={key} ref={drag}>
          <button
            className={`questionaddforquestionbank ${selectedQuestionId === question.guid ? 'selected' : ''}`}
            onClick={() => handleAdd(question)}
            title={intl.formatMessage({ id: 'addQuestionsIcon', defaultMessage: 'Add Questions' })}
          >
            <i className="bi bi-plus-lg darker-icon"></i>
          </button>
          <FillInBlanks questionNode={question} questionNodeIndex={index} qtiModel={qtiModel} printView={3} />
        </div>
      );
    case CustomQuestionsService.Essay:
      return (
        <div className="questionblock w-100" key={key} ref={drag}>
          <button
            className={`questionaddforquestionbank ${selectedQuestionId === question.guid ? 'selected' : ''}`}
            onClick={() => handleAdd(question)}
            title={intl.formatMessage({ id: 'addQuestionsIcon', defaultMessage: 'Add Question' })}
          >
            <i className="bi bi-plus-lg darker-icon"></i>
          </button>
          <Essay questionNode={question} questionNodeIndex={index} qtiModel={qtiModel} printView={3} />
        </div>
      );
    default:
      return null;
  }
};

export default DraggableQuestion;
