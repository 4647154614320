/**
 * exportFileFormats
 * An array of objects representing the available file formats for exporting data.
 * Each object contains a `value`, `text`, and `fileExtension` property.
 */
export const exportFileFormats = [
  { value: 'doc', text: 'MS Word', fileExtension: 'doc' },
  { value: 'pdf', text: 'PDF', fileExtension: 'pdf' },
  { value: 'bbpm', text: 'Blackboard Pool manager', fileExtension: 'zip' },
  { value: 'bbtm', text: 'Blackboard Test manager', fileExtension: 'zip' },
  { value: 'qti21', text: 'QTI 2.1', fileExtension: 'zip' },
];

/**
 * answerAreas
 * An array of objects representing the available answer area options.
 * Each object contains a `value`, `isDisabled`, and `text` property.
 */
export const answerAreas = [
  { value: 'NONE', isDisabled: false, text: 'None' },
  { value: 'BETWEENQUESTIONS', isDisabled: false, text: 'Between questions' },
  { value: 'LEFTSIDE', isDisabled: false, text: 'Left side of the page' },
  { value: 'LASTPAGE', isDisabled: false, text: 'Blank last page' },
];

/**
 * answerKeys
 * An array of objects representing the available answer key options.
 * Each object contains a `value` and `text` property.
 */
export const answerKeys = [
  { value: 'NONE', text: 'None' },
  { value: 'SAMEFILE', text: 'Same file' },
  { value: 'SEPARATEFILE', text: 'Separate file' },
];

/**
 * margins
 * An array of objects representing the available margin options.
 * Each object contains a `value` and `text` property.
 */
export const margins = [
  { value: '0.5', text: '0.5 inch' },
  { value: '1.0', text: '1 inch' },
  { value: '1.5', text: '1.5 inch' },
];

/**
 * An array of page number positions.
 * Each object contains a `value` and `text` property.
 */
export const pageNumbers = [
  { value: 'BOTTOMMIDDLE', text: 'Bottom middle' },
  { value: 'BOTTOMRIGHT', text: 'Bottom right' },
  { value: 'TOPRIGHT', text: 'Top right' },
];
