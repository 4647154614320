import { AxiosError } from 'axios';

/**
 * Downloads a file with the given blob and file name.
 *
 * @param {Blob} blob - The blob object containing the file data.
 * @param {string} fileName - The name of the file to be downloaded.
 */
export function downloadFile(blob, fileName) {
  // Creating new object of file
  const fileURL = window.URL.createObjectURL(blob);

  // Setting various property values
  const alink = document.createElement('a');
  alink.href = fileURL;
  alink.download = fileName;
  alink.click();

  // Clean up the object URL
  window.URL.revokeObjectURL(fileURL);
}

/**
 * Extracts the error message from an Axios error object.
 *
 * @param {AxiosError} error - The Axios error object to extract the message from.
 * @returns {string} The extracted error message.
 */
export const extractErrorMessage = error => {
  if (error instanceof AxiosError) {
    if (error.response) {
      // If the error response has a data property with a message, return it
      if (error.response.data && error.response.data.message) {
        return error.response.data.message;
      }
      // If the error response has a status code, return a message with the status code
      // if (error.response.status) {
      //   return `Request failed with status code ${error.response.status}`;
      // }
      // If all else fails, return the error message
      return error.message;
    }
    // If the error is not an Axios error, return the error message
  } else {
    return error;
  }
};
