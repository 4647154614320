import { toastify } from '../../../components/common/Toastify';
import { getAllQuestions } from '../../../services/userfolder.service';
import { intl } from '../../../utils/localization/Localization';
import QtiService from '../../../utils/qti-converter';

/**
 * Generates an array of book nodes.
 *
 * @param {array} books - An array of book objects.
 * @param {number} disciplineId - The ID of the parent discipline node.
 * @param {number} totalNodesLength - The total number of nodes.
 *
 * @returns {array} An array of book nodes with their IDs, parent discipline ID, and other properties.
 */
export const generateBooks = (books, disciplineId, totalNodesLength) => {
  return books.map((book, index) => ({
    id: totalNodesLength + index + 1,
    parent: disciplineId,
    droppable: true,
    bookGuid: book.guid,
    text: `${book.title}`,
    type: 'book',
  }));
};

/**
 * Generates an array of chapter objects with the specified properties.
 *
 * @param {Object[]} chapters - An array of chapter objects.
 * @param {Object} book - The book object that the chapters belong to.
 *
 * @returns {Object[]} An array of chapter objects with the specified properties.
 */
export const generateChapters = (chapters, book) => {
  return chapters.map((chapter, index) => ({
    id: `${book.bookGuid}-${index}`,
    parent: book.id,
    droppable: true,
    bookGuid: book.bookGuid,
    nodeGuid: chapter.guid,
    text: chapter.title,
    type: 'node',
  }));
};

/**
 * Generate an array of question objects from a given array of questions and parent node.
 *
 * @param {Object[]} questions - The array of questions to generate the question objects from.
 * @param {Object} parentNode - The parent node object that the questions belong to.
 *
 * @returns {Object[]} An array of question objects with the specified properties.
 */
export const generateQuestions = (questions, parentNode) => {
  return questions.map((question, index) => ({
    id: `${parentNode.bookGuid}-${parentNode.nodeGuid}-question-${index}`,
    parent: parentNode.id,
    droppable: false,
    questionGuid: question.guid,
    question: question,
    index: index,
    type: 'question',
  }));
};

/**
 * Generates an array of questions with their corresponding QTI models.
 * @param {Array} questions - An array of question objects.
 *
 * @return {Array} An array of question objects with their corresponding QTI models.
 */
export const generateQuestionsWithQTIModels = questions => {
  return questions.map(question => {
    const {
      qtixml,
      metadata: { quizType },
    } = question;
    question.extendedMetadata = question.metadata.extendedMetadata;
    const qtiModel = QtiService.getQtiModel(qtixml, quizType);
    qtiModel.EditOption = false;

    return { ...question, qtiModel };
  });
};

/**
 * Loads questions from the server and updates the tree data with the results.
 *
 * @param {object} node - The node for which child nodes are to be loaded.
 *
 * @returns {Promise<void>} A promise that resolves when the questions are loaded.
 */
export const loadQuestions = async (node, advancedSearchSelection) => {
  try {
    const queryParams = {};
    if (advancedSearchSelection && advancedSearchSelection.length) {
      queryParams.QTyp = advancedSearchSelection.join(',');
    }
    const questions = await getAllQuestions(node.bookGuid, node.nodeGuid, queryParams);

    if (Array.isArray(questions)) {
      const questionsWithQtiModels = generateQuestionsWithQTIModels(questions);
      const questionNodes = generateQuestions(questionsWithQtiModels, node);

      return questionNodes;
    } else {
      console.error('Expected an array of questions but received:', questions);
      toastify.showErrorToast(intl.formatMessage({ id: 'error.errorOccurredWhileFetchingQuestions' }));

      return [];
    }
  } catch (error) {
    if (error.response) {
      if (error.response.status === 404) {
        toastify.showErrorToast(intl.formatMessage({ id: 'questionerrormsg404' }));
      } else if (error.response.status === 500) {
        toastify.showErrorToast(intl.formatMessage({ id: 'questionerrormsg500' }));
      } else {
        toastify.showErrorToast({ id: 'errormsg' });
      }
    }

    return [];
  }
};
