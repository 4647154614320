import React, { useEffect, useMemo, useState } from 'react';
import { Button, Dropdown } from 'react-bootstrap';
import { FormattedMessage } from 'react-intl';
import './QuestionBanks.css';

const questionTypes = Object.freeze(['TF', 'MC', 'FIB', 'MT', 'MR', 'ES']);

function Advancedsearch({ options, closeDropdown, onAdvancedSearch }) {
  const [selectedOptions, setSelectedOptions] = useState(options);

  useEffect(() => {
    setSelectedOptions(options);
  }, [options]);

  const toggleOption = option => {
    if (selectedOptions.includes(option)) {
      setSelectedOptions(selectedOptions.filter(item => item !== option));
    } else {
      setSelectedOptions([...selectedOptions, option]);
    }
  };

  const handleSearch = () => {
    onAdvancedSearch(selectedOptions);
  };

  const handleCancel = () => {
    setSelectedOptions(options);
    closeDropdown();
  };

  /**
   * Returns a boolean indicating whether the search is disabled based on the selected options.
   *
   * @returns {boolean} True if the search is disabled, false otherwise.
   */
  const isSearchDisabled = useMemo(() => {
    // Check if all selected options are included in the options array.
    return (
      selectedOptions.length === 0 ||
      (selectedOptions.length === options.length && selectedOptions.every(option => options.includes(option)))
    );
  }, [selectedOptions, options]);

  return (
    <div className="advanced-search-container-fluid bodyas-container">
      <span className="type-label">
        <FormattedMessage id="message.type" />:
      </span>
      <Button className="close-button" variant="link" onClick={handleCancel} aria-label="close" title="Close">
        <i className="bi bi-x-lg"></i>
      </Button>
      <div className="advanced-search-options">
        <div className="advanced-button-options">
          <div className="buttons-container">
            {questionTypes.map(type => (
              <Button
                key={type}
                variant={selectedOptions.includes(type) ? 'primary' : 'secondary'}
                onClick={() => toggleOption(type)}
              >
                <FormattedMessage id={`questionTypes.${type}`} />
              </Button>
            ))}
          </div>
        </div>

        <div className="custom-divider"></div>
        <div className="advanced-action-buttons">
          <Button variant="secondary" onClick={handleCancel}>
            <FormattedMessage id="message.cancel" />
          </Button>
          <Button onClick={handleSearch} disabled={isSearchDisabled}>
            <FormattedMessage id="message.search" />
          </Button>
        </div>
      </div>
    </div>
  );
}

export default Advancedsearch;
