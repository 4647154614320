import React, { useEffect, useState } from 'react';
import { Button, Form } from 'react-bootstrap';
import { FormattedMessage } from 'react-intl';

const AddFolder = ({ name, parentId, onFolderAdd, onClose }) => {
  const [showTextBox, setShowTextBox] = useState(false);
  const [folderName, setFolderName] = useState(name || '');
  const [folderParentGuid, setFolderParentGuid] = useState(parentId || '');
  const [isEditing, setIsEditing] = useState(false);

  useEffect(() => {
    if (name) {
      setShowTextBox(true);
      setIsEditing(true);
      setFolderName(name);
      setFolderParentGuid(parentId);
    }
  }, [name]);

  const handleAddFolderClick = () => {
    setShowTextBox(true);
    setIsEditing(false);
    setFolderName('');
  };

  const handleTextBoxClose = () => {
    setShowTextBox(false);
    setFolderName('');
    setIsEditing(false);
    if (onClose) {
      onClose();
    }
  };

  const handleSaveFolder = () => {
    onFolderAdd(folderName, isEditing, folderParentGuid);
    setShowTextBox(false);
    setFolderName('');
    setIsEditing(false);
  };

  return (
    <div>
      <div className="button-container">
        <Button className="color-black" variant="outline-light" onClick={handleAddFolderClick}>
          <i className="fa-solid fa-plus"></i>&ensp;
          <FormattedMessage id="yourtests.addfolder" />
        </Button>
      </div>
      {showTextBox && (
        <div className="text-box d-flex align-items-center p-2">
          <div className="flex-grow-1 mr-2">
            <Form.Control
              type="text"
              placeholder="Enter folder name"
              value={folderName}
              onChange={e => setFolderName(e.target.value)}
              className="rounded ml-1"
            />
          </div>
          <div className="d-flex">
            <Button
              onClick={handleSaveFolder}
              className=""
              aria-label="Tick mark"
              title="Check Mark"
              style={{ color: 'black', backgroundColor: 'white' }}
            >
              <i className="fa-solid fa-check"></i>
            </Button>
            <Button
              onClick={handleTextBoxClose}
              className="closebtn m1-2"
              aria-label="close mark"
              title="Close"
              style={{ color: 'black', backgroundColor: 'white' }}
            >
              <i className="fa-solid fa-xmark"></i>
            </Button>
          </div>
        </div>
      )}
    </div>
  );
};

export default React.memo(AddFolder);
