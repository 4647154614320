import httpInterceptor from '../httpHelper/httpHelper';
import { extractErrorMessage } from '../utils/common';

let url = process.env.REACT_APP_API_URL;

export const getUserProfilesettings = async () => {
  try {
    const response = await httpInterceptor.get(`${url}/settings/questionmetadata`);
    return response?.data;
  } catch (error) {
    throw extractErrorMessage(error);
  }
};

export const saveUserProfileQuestionMetadata = async selectedSettings => {
  try {
    const response = await httpInterceptor.post(`${url}/settings/questionmetadata`, selectedSettings);
    return response?.data;
  } catch (error) {
    throw extractErrorMessage(error);
  }
};
