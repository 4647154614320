import React, { useState } from 'react';
import { FormattedMessage, useIntl } from 'react-intl';
import { Button, Form } from 'react-bootstrap';
import { v4 as uuidv4 } from 'uuid';
import { useAppContext } from '../../../../context/AppContext';
import { toastify } from '../../../common/Toastify';
import { Editor, EditorProvider, Toolbar } from '../../../lib/rte';
import QtiService from '../../../../utils/qti-converter';
import CustomQuestionsService from '../../../../services/CustomQuestionsService';
import FormatAndAddExtendedMetadataSection from '../FormatAndAddExtendedMetadataSection';

const optionsCount = process.env.REACT_APP_ANSWER_OPTIONS_COUNT;

const generateOptions = (options, answers = []) => {
  if (!options) return [{ id: uuidv4() }, { id: uuidv4() }, { id: uuidv4() }, { id: uuidv4() }];

  return options.map((option, index) => ({
    id: uuidv4(),
    value: option,
    checked: answers[index] || false,
  }));
};

const initialFormatAndExtendedMetadata = questionNode => {
  const initialData = {
    Orientation: questionNode.qtiModel ? String(questionNode.qtiModel.Orientation) : 'false',
    questionId: questionNode ? questionNode?.extendedMetadata?.questionId : '',
    pageReference: questionNode ? questionNode?.extendedMetadata?.pageReference : '',
    topic: questionNode ? questionNode?.extendedMetadata?.topic : '',
    skill: questionNode ? questionNode?.extendedMetadata?.skill : '',
    objective: questionNode ? questionNode?.extendedMetadata?.objective : '',
    difficulty: questionNode ? questionNode?.extendedMetadata?.difficulty : '',
  };

  if (Array.isArray(questionNode.extendedMetadata)) {
    questionNode.extendedMetadata.forEach(metadata => {
      initialData[metadata.name] = metadata.value;
    });
  }

  return initialData;
};

const MultipleResponseEditView = ({ questionNode, questionNodeIndex, onQuestionDelete, onQuestionStateChange }) => {
  const intl = useIntl();
  const { selectedTest, setSelectedTest } = useAppContext();

  const [questionData, setQuestionData] = useState({
    Caption: questionNode.qtiModel ? questionNode.qtiModel.Caption : '',
    optionsRecords: generateOptions(questionNode?.qtiModel?.Options, questionNode?.qtiModel?.CorrectAnswer),
  });

  const [formatAndExtendedMetadata, setFormatAndExtendedMetadata] = useState(
    initialFormatAndExtendedMetadata(questionNode)
  );

  const handleOptionChecked = (e, item) => {
    const { checked } = e.target;

    const options = questionData.optionsRecords.map(option => {
      if (option.id === item.id) {
        option.checked = checked;
      }

      return option;
    });

    setQuestionData(prev => ({
      ...prev,
      optionsRecords: options,
    }));
  };

  const handleOptionValueChange = (value, item) => {
    const options = questionData.optionsRecords.map(option => {
      if (option.id === item.id) {
        option.value = value;
      }

      return option;
    });

    setQuestionData(prev => ({
      ...prev,
      optionsRecords: options,
    }));
  };

  const handleAddOption = () => {
    if (questionData.optionsRecords.length < optionsCount) {
      setQuestionData(prev => ({
        ...prev,
        optionsRecords: [...prev.optionsRecords, { id: uuidv4(), value: '', checked: false }],
      }));
    } else {
      let msg = intl.formatMessage({ id: 'warning.moreoptions' });
      msg = msg.replace('{$}', optionsCount);
      toastify.showWarningToast(msg);
    }
  };

  const handleRemoveOption = item => {
    if (questionData.optionsRecords.length > 2) {
      const options = questionData.optionsRecords.filter(option => option.id !== item.id);

      setQuestionData(prev => ({
        ...prev,
        optionsRecords: options,
      }));
    }
  };

  const handleChange = e => {
    const { name, value } = e.target || e;

    setQuestionData(prev => ({ ...prev, [name]: value }));
  };

  const handleDelete = e => {
    e.preventDefault();
    if (questionNode.qtiModel.EditOption) {
      questionNode.qtiModel.EditOption = false;
    }
    onQuestionDelete(questionNodeIndex);
    onQuestionStateChange(false);
  };

  const handleSubmit = e => {
    e.preventDefault();
    if (!questionNode) return; // early return if questionNode is falsy

    // Update questionNode properties
    questionNode.qtiModel.Caption = questionData.Caption;
    questionNode.qtiModel.Options = questionData.optionsRecords.map(x => x.value);
    questionNode.qtiModel.CorrectAnswer = questionData.optionsRecords.map(x => x.checked);
    questionNode.qtiModel.Orientation = formatAndExtendedMetadata.Orientation;
    questionNode.qtiModel.EditOption = false;
    questionNode.extendedMetadata = {
      questionId: formatAndExtendedMetadata.questionId,
      pageReference: formatAndExtendedMetadata.pageReference,
      topic: formatAndExtendedMetadata.topic,
      skill: formatAndExtendedMetadata.skill,
      objective: formatAndExtendedMetadata.objective,
      difficulty: formatAndExtendedMetadata.difficulty,
    };

    // Convert JSON to XML and update questionNode data
    const jsonToXML = QtiService.getQtiXML(questionNode);
    questionNode.data = jsonToXML;

    // Get question templates and extract HTML
    const questionTemplates = CustomQuestionsService.questionTemplates(questionNode);
    let xmlToHtml = questionTemplates[0].textHTML;

    // Update the selected test
    const testObj = { ...selectedTest }; // Create a copy of selectedTest

    // Find if any question in the array has the same itemId
    const existingQuestion = testObj.questions.find(q => q.itemId === questionNode.itemId);

    if (!existingQuestion) {
      // If the question doesn't exist, add it to the end of the array
      questionNode.spaceLine = questionData.spaceLine || 0;
      questionNode.textHTML = xmlToHtml;
    } else {
      // If the question already exists, update it
      testObj.questions[questionNodeIndex] = {
        ...testObj.questions[questionNodeIndex],
        spaceLine: questionData.spaceLine || 0,
        textHTML: xmlToHtml,
      };
    }

    // Find if any question in the array has the same itemId
    // const existingQuestionIndex = testObj.questions.findIndex(q => q.itemId === questionNode.itemId);

    // if (existingQuestionIndex !== -1) {
    //   // If the question already exists, update it
    //   testObj.questions[existingQuestionIndex] = {
    //     ...testObj.questions[existingQuestionIndex],
    //     spaceLine: questionData.spaceLine || 0,
    //     textHTML: xmlToHtml,
    //   };
    // } else {
    //   // If the question doesn't exist, add it to the end of the array
    //   testObj.questions.push({
    //     ...questionNode,
    //     spaceLine: questionData.spaceLine || 0,
    //     textHTML: xmlToHtml,
    //   });
    // }

    // Update the selected test with the modified questions array
    setSelectedTest(testObj);
  };

  const isCaptionFilled = questionData.Caption.trim() !== '';
  const areOptionsFilled = questionData.optionsRecords.every(opt => opt.value && opt.value.trim() !== '');
  const isViewButtonEnabled = isCaptionFilled && areOptionsFilled;

  return (
    <div className="m-2 border rounded p-3 bg-light">
      <EditorProvider>
        <Toolbar />
        <Form className="editmode paddingright10">
          <Form.Group className="mb-3" controlId="exampleForm.ControlInput1">
            <Form.Label className="mb-1">
              <b>{questionNode.qtiModel.QstnSectionTitle}</b>
            </Form.Label>

            <Editor
              name="Caption"
              value={questionData.Caption}
              placeholder={questionNode.qtiModel.EditCaption}
              className="mb-4"
              onChange={value => handleChange({ name: 'Caption', value })}
            />

            <Form.Group className="mb-1 mt-3 d-flex flex-wrap">
              {questionData.optionsRecords.map((optItem, index) => {
                return (
                  <Form.Group key={index} className="mc-flex-row mb-2">
                    <div className="mc-col mc-col-1" tabIndex="0">
                      <Form.Check
                        type="checkbox"
                        checked={optItem.checked}
                        className="item-1"
                        name="CorrectAnswer"
                        onChange={e => handleOptionChecked(e, optItem)}
                      />
                    </div>
                    <div className="mc-col mc-col-2">
                      <Editor
                        key={optItem.id}
                        name={index}
                        value={optItem.value}
                        placeholder="Enter Answer"
                        className="item-2"
                        onChange={value => handleOptionValueChange(value, optItem)}
                      />
                    </div>
                    <Button
                      className="opt-close-button highlight-icon-button"
                      variant="link"
                      title={intl.formatMessage({
                        id: 'cancelButtonforOptions',
                        defaultMessage: 'Delete Option',
                      })}
                      onClick={() => handleRemoveOption(optItem)}
                      disabled={optItem.checked || questionData.optionsRecords.length <= 2}
                    >
                      <i className="bi bi-x-lg"></i>
                    </Button>
                  </Form.Group>
                );
              })}
            </Form.Group>
            <div className="d-flex justify-content-end">
              <Button onClick={handleAddOption}>
                <FormattedMessage id="addOptionsforMultiplechoice" />
              </Button>
            </div>
          </Form.Group>

          <FormatAndAddExtendedMetadataSection
            data={formatAndExtendedMetadata}
            setData={setFormatAndExtendedMetadata}
          />
        </Form>
        <div className="mb-1 d-flex justify-content-end">
          <div
            className="tooltip-wrapper"
            title={intl.formatMessage({ id: 'viewButtonTitle', defaultMessage: 'View' })}
            tabIndex="0"
          >
            <button
              className={`savelink ${!isViewButtonEnabled ? 'disabled-link' : ''}`}
              onClick={handleSubmit}
              tabIndex={!isViewButtonEnabled ? -1 : 0}
              disabled={!isViewButtonEnabled}
              aria-label="view"
            >
              <i className="bi bi-eye"></i>
            </button>
          </div>
          <button
            className="deletelink"
            onClick={handleDelete}
            title={intl.formatMessage({ id: 'deleteButtonTitle', defaultMessage: 'Remove' })}
          >
            <i className="bi bi-trash"></i>
          </button>
        </div>
      </EditorProvider>
    </div>
  );
};

export default MultipleResponseEditView;
