import React, { useEffect, useState } from 'react';
import { useAppContext } from '../context/AppContext';
import { FormattedMessage, useIntl } from 'react-intl';
import { Form } from 'react-bootstrap';
import { useDrop } from 'react-dnd';
import QuestionsBanksTips from './testTabs/QuestionsBanksTips/QuestionsBanksTips';
import Essay from './questions/Essay';
import FillInBlanks from './questions/FillInBlanks';
import Matching from './questions/Matching';
import MultipleChoice from './questions/MultipleChoice';
import MultipleResponse from './questions/MultipleResponse';
import TrueFalse from './questions/TrueFalse';
import CustomQuestionsService from '../services/CustomQuestionsService';
import TreeViewTestCreate from './TreeViewTestCreate';
import DuplicateTestConfirmationModal from './modals/DuplicateTestConfirmationModal';
import { getQuestionFromDto, transformQuestionTemplateToQuestion } from '../utils/questions-utils';
import { sanitizeTitle } from '../utils/test-utils';
import './TestCreate.css';
import './_tables.css';
import { toastify } from './common/Toastify';

const TestCreate = () => {
  const { selectedTest, dispatchEvent, setSelectedTest } = useAppContext();
  const [tabTitle, setTabTitle] = useState(selectedTest?.title || '');
  const [isEditing, setIsEditing] = useState(true);
  const [refreshChildren, setRefreshChildren] = useState(false);
  const intl = useIntl();
  const questionCount = process.env.REACT_APP_TEST_QUESTIONS_COUNT;

  useEffect(() => {
    setTabTitle(selectedTest?.title || '');
  }, [selectedTest]);

  const handleTitleChange = event => {
    let newTitle = sanitizeTitle(event.target.value);

    setTabTitle(newTitle);
    setIsEditing(true);

    // Update the title in the selectedTest object
    if (selectedTest && selectedTest.id) {
      // Create a copy of the selectedTest object
      const updatedSelectedTest = { ...selectedTest };
      // Update the title property with the new title
      updatedSelectedTest.title = newTitle;
      // Dispatch an action to update the selectedTest object in the context
      dispatchEvent('UPDATE_TEST_TITLE', updatedSelectedTest);
    }
  };

  const handleSubmit = event => {
    event.preventDefault();
    if (tabTitle.trim().length === 0) {
      return;
    } else {
      dispatchEvent('UPDATE_TEST_TITLE', {
        id: selectedTest.id,
        title: tabTitle,
      });
      setIsEditing(false);
    }
  };

  const [{ canDrop, isOver }, drop] = useDrop({
    accept: ['QUESTION_TEMPLATE', 'TREE_NODE', 'SAVED_QUESTION'],
    drop: item => {
      console.log('Dropped node:', item);
      if (selectedTest.questions.length >= questionCount) {
        var msg = intl.formatMessage({ id: 'warning.morequestions' });
        msg = msg.replace('{$}', questionCount);
        toastify.showWarningToast(msg);
        return;
      }

      let copyItem = JSON.parse(JSON.stringify(item));
      let newQuestion;
      if (item.type === 'QUESTION_TEMPLATE') {
        newQuestion = transformQuestionTemplateToQuestion(copyItem.questionTemplate);
      } else if (item.type === 'TREE_NODE') {
        newQuestion = transformQuestionTemplateToQuestion(copyItem.questionTemplate);
      } else if (item.question) {
        newQuestion = getQuestionFromDto(copyItem.question);
      } else {
        newQuestion = transformQuestionTemplateToQuestion(copyItem.questionTemplate);
      }

      dispatchEvent('ADD_NEW_QUESTION_TO_TEST', newQuestion);

      setIsEditing(true);
    },
    collect: monitor => ({
      isOver: monitor.isOver(),
      canDrop: monitor.canDrop(),
    }),
  });
  const handleQuestionState = edit => {
    setRefreshChildren(!refreshChildren);
  };

  const handleQuestionDelete = deleteIndex => {
    if (deleteIndex > -1) {
      dispatchEvent('DELETE_QUESTION_AT_INDEX', { index: deleteIndex });
    }
  };

  const renderQuestions = (questionNode, index) => {
    const key = selectedTest.id + questionNode.itemId || questionNode.guid;
    if (questionNode.quizType) {
      switch (questionNode.quizType) {
        case CustomQuestionsService.MultipleChoice:
          return (
            <MultipleChoice
              questionNode={questionNode}
              printView={2}
              key={key}
              questionNodeIndex={index}
              onQuestionStateChange={handleQuestionState}
              onQuestionDelete={handleQuestionDelete}
              selectedTest={selectedTest}
              setSelectedTest={setSelectedTest}
            />
          );
        case CustomQuestionsService.MultipleResponse:
          return (
            <MultipleResponse
              questionNode={questionNode}
              printView={2}
              key={key}
              questionNodeIndex={index}
              onQuestionStateChange={handleQuestionState}
              onQuestionDelete={handleQuestionDelete}
              selectedTest={selectedTest}
              setSelectedTest={setSelectedTest}
            />
          );
        case CustomQuestionsService.TrueFalse:
          return (
            <TrueFalse
              questionNode={questionNode}
              printView={2}
              key={key}
              questionNodeIndex={index}
              onQuestionStateChange={handleQuestionState}
              onQuestionDelete={handleQuestionDelete}
              selectedTest={selectedTest}
              setSelectedTest={setSelectedTest}
            />
          );
        case CustomQuestionsService.Matching:
          return (
            <Matching
              questionNode={questionNode}
              printView={2}
              key={key}
              questionNodeIndex={index}
              onQuestionStateChange={handleQuestionState}
              onQuestionDelete={handleQuestionDelete}
              selectedTest={selectedTest}
              setSelectedTest={setSelectedTest}
            />
          );
        case CustomQuestionsService.FillInBlanks:
          return (
            <FillInBlanks
              questionNode={questionNode}
              printView={2}
              key={key}
              questionNodeIndex={index}
              onQuestionStateChange={handleQuestionState}
              onQuestionDelete={handleQuestionDelete}
              selectedTest={selectedTest}
              setSelectedTest={setSelectedTest}
            />
          );
        case CustomQuestionsService.Essay:
          return (
            <Essay
              questionNode={questionNode}
              printView={2}
              key={key}
              questionNodeIndex={index}
              onQuestionStateChange={handleQuestionState}
              onQuestionDelete={handleQuestionDelete}
              selectedTest={selectedTest}
              setSelectedTest={setSelectedTest}
            />
          );
        default:
          return null;
      }
    } else if (questionNode.metadata && questionNode.metadata.quizType) {
      switch (questionNode.metadata.quizType) {
        case CustomQuestionsService.MultipleChoice:
          return (
            <MultipleChoice
              questionNode={questionNode}
              key={key}
              printView={2}
              questionNodeIndex={index}
              onQuestionStateChange={handleQuestionState}
              onQuestionDelete={handleQuestionDelete}
              selectedTest={selectedTest}
              setSelectedTest={setSelectedTest}
            />
          );
        case CustomQuestionsService.MultipleResponse:
          return (
            <MultipleResponse
              questionNode={questionNode}
              key={key}
              printView={2}
              questionNodeIndex={index}
              onQuestionStateChange={handleQuestionState}
              onQuestionDelete={handleQuestionDelete}
              selectedTest={selectedTest}
              setSelectedTest={setSelectedTest}
            />
          );
        case CustomQuestionsService.TrueFalse:
          return (
            <TrueFalse
              questionNode={questionNode}
              key={key}
              printView={2}
              questionNodeIndex={index}
              onQuestionStateChange={handleQuestionState}
              onQuestionDelete={handleQuestionDelete}
              selectedTest={selectedTest}
              setSelectedTest={setSelectedTest}
            />
          );
        case CustomQuestionsService.Matching:
          return (
            <Matching
              questionNode={questionNode}
              key={key}
              printView={2}
              questionNodeIndex={index}
              onQuestionStateChange={handleQuestionState}
              onQuestionDelete={handleQuestionDelete}
              selectedTest={selectedTest}
              setSelectedTest={setSelectedTest}
            />
          );
        case CustomQuestionsService.FillInBlanks:
          return (
            <FillInBlanks
              questionNode={questionNode}
              key={key}
              printView={2}
              questionNodeIndex={index}
              onQuestionStateChange={handleQuestionState}
              onQuestionDelete={handleQuestionDelete}
              selectedTest={selectedTest}
              setSelectedTest={setSelectedTest}
            />
          );
        case CustomQuestionsService.Essay:
          return (
            <Essay
              questionNode={questionNode}
              key={key}
              printView={2}
              questionNodeIndex={index}
              onQuestionStateChange={handleQuestionState}
              onQuestionDelete={handleQuestionDelete}
              selectedTest={selectedTest}
              setSelectedTest={setSelectedTest}
            />
          );
        default:
          return null;
      }
    }
  };

  return (
    <>
      <div className="test-container pb-0">
        <div className="d-flex align-items-center test-name-edit-container p1">
          <div className="gap-4 d-flex align-items-center w-100">
            <div className="ml-2 p-500">
              <FormattedMessage id="testName" />
            </div>
            <Form className="flex-grow-1" onSubmit={handleSubmit}>
              <Form.Control
                type="text"
                name="title"
                placeholder="Enter Test title"
                value={tabTitle}
                onChange={handleTitleChange}
                className="rounded testNameInputBox"
                required={true}
              />
            </Form>
          </div>
        </div>
      </div>
      <div className="test-questions-container">
        {selectedTest?.questions?.length ? (
          <>
            <div className="pt-0">
              <TreeViewTestCreate data={selectedTest.questions} renderQuestions={renderQuestions} />
            </div>
            <div ref={drop} className={`${canDrop && isOver && !isEditing ? 'drop-active' : ''}`}>
              <div className="drag-container align-items-center d-flex justify-content-center">
                <FormattedMessage id="dragQuestionsHereTestcreate" />
              </div>
            </div>
          </>
        ) : (
          <div ref={drop}>
            <QuestionsBanksTips />
          </div>
        )}
      </div>

      <DuplicateTestConfirmationModal />
    </>
  );
};

export default TestCreate;
