import { useRef, useState } from 'react';
import { Button, Form, FormGroup } from 'react-bootstrap';
import { FormattedMessage, useIntl } from 'react-intl';
import jquery from 'jquery';
import DOMPurify from 'dompurify';
import { useAppContext } from '../../../../context/AppContext';
import QtiService from '../../../../utils/qti-converter';
import { getPrintModeFbCaption } from '../../FillInBlanks';
import FormatAndAddExtendedMetadataSection from '../FormatAndAddExtendedMetadataSection';
import { Editor, EditorProvider, Toolbar } from '../../../lib/rte';
import { toastify } from '../../../common/Toastify';

const MAX_BLANKS_COUNT = process.env.REACT_APP_ANSWER_OPTIONS_COUNT;

const initialFormatAndExtendedMetadata = questionNode => {
  const initialData = {
    questionId: questionNode ? questionNode?.extendedMetadata?.questionId : '',
    pageReference: questionNode ? questionNode?.extendedMetadata?.pageReference : '',
    topic: questionNode ? questionNode?.extendedMetadata?.topic : '',
    skill: questionNode ? questionNode?.extendedMetadata?.skill : '',
    objective: questionNode ? questionNode?.extendedMetadata?.objective : '',
    difficulty: questionNode ? questionNode?.extendedMetadata?.difficulty : '',
  };

  if (Array.isArray(questionNode.extendedMetadata)) {
    questionNode.extendedMetadata.forEach(metadata => {
      initialData[metadata.name] = metadata.value;
    });
  }

  return initialData;
};

const getFIBBlanks = (text, existingAnswers) => {
  const domContent = jquery(jquery.parseHTML(text));
  const buttons = domContent.find('button');

  const blanks = [];
  buttons.each((index, element) => {
    const button = jquery(element);
    const key = button.attr('data-key');
    let answer = '';
    let blankSpace = 20;

    const existingBlank = existingAnswers?.find(correctAnswer => correctAnswer.key === key);

    if (existingBlank) {
      answer = existingBlank.answer;
      blankSpace = existingBlank.blankSpace;
    }

    blanks.push({
      key,
      name: 'RESPONSE_' + (index + 1),
      answer,
      blankSpace,
    });
  });

  return blanks;
};

const FillInBlanksEditView = ({ questionNode, questionNodeIndex, onQuestionStateChange, onQuestionDelete }) => {
  const { selectedTest, setSelectedTest } = useAppContext();
  const intl = useIntl();
  const fibEditorRef = useRef();

  const [formData, setFormData] = useState(() => {
    const Caption = questionNode.qtiModel ? questionNode.qtiModel.Caption : '';
    const blanks = getFIBBlanks(Caption, questionNode.qtiModel.Blanks);

    return {
      Caption,
      blanks,
    };
  });

  const [formatAndExtendedMetadata, setFormatAndExtendedMetadata] = useState(
    initialFormatAndExtendedMetadata(questionNode)
  );

  const handleFIBAnswerChange = (item, value) => {
    setFormData(prevFormData => ({
      ...prevFormData,
      blanks: prevFormData.blanks.map(blank => {
        if (blank.key === item.key) {
          blank.answer = value;
        }

        return blank;
      }),
    }));
  };

  const handleFIBBlankSpace = (item, value) => {
    setFormData(prevFormData => ({
      ...prevFormData,
      blanks: prevFormData.blanks.map(blank => {
        if (blank.key === item.key) {
          blank.blankSpace = value;
        }

        return blank;
      }),
    }));
  };

  const handleAddBlank = event => {
    if (formData.blanks.length < MAX_BLANKS_COUNT) {
      fibEditorRef.current.insertBlank();
    } else {
      const msg = intl.formatMessage({ id: 'warning.cannotAddMoreBlanks' }, { count: MAX_BLANKS_COUNT });
      toastify.showWarningToast(msg);
    }
    event.target.blur();
  };

  const handleDeleteBlank = key => {
    fibEditorRef.current.deleteBlank(key);
  };

  const handleSubmit = e => {
    e.preventDefault();
    if (questionNode) {
      questionNode.qtiModel.Caption = formData.Caption;
      questionNode.qtiModel.Blanks = formData.blanks.map(blank => ({
        ...blank,
        answer: DOMPurify.sanitize(blank.answer),
      }));
      questionNode.qtiModel.EditOption = false;
      questionNode.extendedMetadata = {
        questionId: formatAndExtendedMetadata.questionId,
        pageReference: formatAndExtendedMetadata.pageReference,
        topic: formatAndExtendedMetadata.topic,
        skill: formatAndExtendedMetadata.skill,
        objective: formatAndExtendedMetadata.objective,
        difficulty: formatAndExtendedMetadata.difficulty,
      };
      let jsonToXML = QtiService.getQtiXML(questionNode);
      questionNode.data = jsonToXML;

      let xmlToHtml = getPrintModeFbCaption(questionNode.qtiModel.Caption, formData.blanks);

      const testObj = { ...selectedTest }; // Create a copy of selectedTest

      // Find if any question in the array has the same itemId
      // Find if any question in the array has the same itemId
      const existingQuestion = testObj.questions.find(q => q.itemId === questionNode.itemId);

      if (!existingQuestion) {
        // If the question doesn't exist, add it to the end of the array
        questionNode.spaceLine = formData.spaceLine || 0;
        questionNode.textHTML = xmlToHtml;
      } else {
        // If the question already exists, update it
        testObj.questions[questionNodeIndex] = {
          ...testObj.questions[questionNodeIndex],
          spaceLine: formData.spaceLine || 0,
          textHTML: xmlToHtml,
        };
      }

      setSelectedTest(testObj);
    }
    onQuestionStateChange(false);
  };

  const handleRemoveQuestion = e => {
    e.preventDefault();
    if (questionNode.qtiModel.EditOption) {
      questionNode.qtiModel.EditOption = false;
      onQuestionDelete(questionNodeIndex);
      onQuestionStateChange(false);
    } else {
      onQuestionDelete(questionNodeIndex);
      onQuestionStateChange(false);
    }
  };

  const handleEditorChange = text => {
    const blanks = getFIBBlanks(text, formData.blanks);

    setFormData(data => ({ ...data, Caption: text, blanks }));
  };

  const isCaptionFilled = formData.Caption.trim() !== '';
  const areOptionsFilled = formData.blanks?.every(blank => blank.answer.trim() !== '');
  const isViewButtonEnabled = isCaptionFilled && areOptionsFilled;

  return (
    <div className="m-2 border rounded p-3 prb-1 bg-light">
      <EditorProvider>
        <Toolbar />
        <Form className="editmode paddingright10">
          <Form.Group className="mb-3" controlId="exampleForm.ControlInput1">
            <Form.Label className="mb-1">
              <b>{questionNode.qtiModel.QstnSectionTitle}</b>
            </Form.Label>
            <Editor
              ref={fibEditorRef}
              placeholder={questionNode.qtiModel.EditCaption}
              allowBlanks={true}
              value={formData.Caption}
              onChange={handleEditorChange}
              className="rounded form-control fib-content-area"
            />
            <div className="float-right">
              <Button
                id="dropdown-item-button"
                title={intl.formatMessage({ id: 'addBlankButton', defaultMessage: 'Add Blank' })}
                className="btn-test mt-3 mb-1 mb-sm-0 mr-sm-1 mr-1"
                onClick={handleAddBlank}
              >
                <FormattedMessage id="addBlankButton" defaultMessage="Add Blank" />
              </Button>
            </div>
            {formData.blanks?.length > 0 && (
              <div className="fib-answers-area mt-3 clear-both">
                <b>
                  <FormattedMessage id="correctAnswerFillInBlanks" defaultMessage="Correct Answer" />
                </b>

                <div id="fbAnswerContainer" className="mb-1 mt-3 d-flex flex-wrap">
                  {formData.blanks.map((item, index) => {
                    return (
                      <div key={index} className="mc-flex-row mb-2">
                        <div className="mc-col fb-col-1">{String.fromCharCode(65 + index) + '.'}</div>
                        <div className="mc-col fb-col-2">
                          <Form.Control
                            type="text"
                            name={item.name}
                            value={item.answer}
                            placeholder={'Enter the correct answer for blank ' + String.fromCharCode(65 + index)}
                            onChange={e => handleFIBAnswerChange(item, e.target.value)}
                          />
                        </div>
                        <div className="d-flex m-1 gap-1">
                          <FormGroup>
                            <Form.Select
                              id="blankSize"
                              value={item.blankSpace}
                              onChange={e => handleFIBBlankSpace(item, Number(e.target.value))}
                            >
                              <option value="" disabled className="blanksize">
                                <FormattedMessage id="blankSizeLabel" defaultMessage="Blank Size" />
                              </option>
                              <option id="option1" value="20" name={item.name} className="blanksize">
                                <FormattedMessage id="smallOption" defaultMessage="Small" />
                              </option>
                              <option id="option2" value="50" name={item.name} className="blanksize">
                                <FormattedMessage id="mediumOption" defaultMessage="Medium" />
                              </option>
                              <option value="100" id="option3" name={item.name} className="blanksize">
                                <FormattedMessage id="largeOption" defaultMessage="Large" />
                              </option>
                            </Form.Select>
                          </FormGroup>
                          <Button
                            className="opt-close-button highlight-icon-button "
                            title={intl.formatMessage({
                              id: 'cancelButtonforOptions',
                              defaultMessage: 'Delete Option',
                            })}
                            variant="link"
                            onClick={() => handleDeleteBlank(item.key)}
                          >
                            <i className="bi bi-x-lg"></i>
                          </Button>
                        </div>
                      </div>
                    );
                  })}
                </div>
              </div>
            )}
          </Form.Group>
          <FormatAndAddExtendedMetadataSection
            data={formatAndExtendedMetadata}
            setData={setFormatAndExtendedMetadata}
          />
        </Form>
      </EditorProvider>
      <div className="mb-1 d-flex justify-content-end">
        <div
          className="tooltip-wrapper"
          title={intl.formatMessage({ id: 'viewButtonTitle', defaultMessage: 'View' })}
          tabIndex="0"
        >
          <button
            className={`savelink ${isViewButtonEnabled ? '' : 'disabled-link'}`}
            onClick={handleSubmit}
            tabIndex={isViewButtonEnabled ? 0 : -1}
            aria-label="view"
          >
            <i className="bi bi-eye"></i>
          </button>
        </div>
        <button
          className="deletelink"
          onClick={handleRemoveQuestion}
          title={intl.formatMessage({ id: 'deleteButtonTitle', defaultMessage: 'Remove' })}
        >
          <i className="bi bi-trash"></i>
        </button>
      </div>
    </div>
  );
};

export default FillInBlanksEditView;
