import React, { useEffect, useState } from 'react';
import { Modal, Button, Form, Row, Col } from 'react-bootstrap';
import { FormattedMessage, useIntl } from 'react-intl';
import { useAppContext } from '../../context/AppContext';
import { toastify } from '../../components/common/Toastify';
import { saveUserProfileQuestionMetadata } from '../../services/profile.service';
import './profile.css';
import { METADATA_OPTIONS } from '../../config/metadata-constants';

const SettingsModal = ({ show, handleClose }) => {
  const intl = useIntl();
  const { userMetadata, dispatchEvent } = useAppContext();

  const [metadataOptions, setMetadataOptions] = useState(
    Object.values(METADATA_OPTIONS).map(option => ({
      label: intl.formatMessage({ id: `metadata.${option}` }),
      value: option,
      checked: false,
    }))
  );

  useEffect(() => {
    if (userMetadata) {
      applyDefaultSelection();
    } else {
      dispatchEvent('FETCH_USER_METADATA');
    }
  }, [userMetadata]);

  /**
   * Applies default selection to metadata options based on user metadata.
   */
  const applyDefaultSelection = () => {
    const updatedOptions = metadataOptions.map(option => ({
      ...option,
      checked: userMetadata.includes(option.value),
    }));

    setMetadataOptions(updatedOptions);
  };

  /**
   * Handles saving the user's metadata options.
   */
  const handleSave = async () => {
    try {
      dispatchEvent('SHOW_LOADER');

      // Filter the metadata options to only include the selected ones
      const selectedOptions = metadataOptions.filter(option => option.checked).map(option => option.value);

      // Save the user's metadata options
      await saveUserProfileQuestionMetadata(selectedOptions);

      // Dispatch an event to update the user's metadata
      dispatchEvent('UPDATE_USER_METADATA', selectedOptions);

      // Show a success toast
      toastify.showSuccessToast(intl.formatMessage({ id: 'success.saveSettings' }));
    } catch (error) {
      // Show an error toast
      toastify.showErrorToast(intl.formatMessage({ id: 'error.saveSettings' }));
    } finally {
      // Close the modal
      handleClose();
      dispatchEvent('HIDE_LOADER');
    }
  };

  /**
   * Handles a checkbox change event.
   *
   * @param {object} current - The current checkbox option.
   * @param {boolean} isChecked - Whether the checkbox is checked or not.
   */
  const handleCheckboxChange = (current, isChecked) => {
    // Updates the metadata options by toggling the checked state of the current option.
    setMetadataOptions(
      metadataOptions.map(option => {
        return option.value === current.value ? { ...option, checked: isChecked } : option;
      })
    );
  };

  return (
    <div style={{ position: 'relative' }}>
      <Modal show={show} onHide={handleClose} backdrop="static" keyboard={false} centered className="settings-modal">
        <Modal.Header backdrop="static" keyboard={false} closeButton title="Close">
          <Modal.Title>
            <FormattedMessage id="metadataTitle" />
          </Modal.Title>
        </Modal.Header>

        <Modal.Body>
          <>
            <Form.Label>
              <FormattedMessage id="settings metadata" />
            </Form.Label>
            <Form>
              <Form.Group as={Row}>
                <Col>
                  {metadataOptions.map((option, index) => (
                    <Form.Check
                      key={index}
                      type="checkbox"
                      label={option.label}
                      id={`${index}`}
                      checked={option.checked}
                      onChange={e => handleCheckboxChange(option, e.target.checked)}
                    />
                  ))}
                </Col>
              </Form.Group>
            </Form>
          </>
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={handleClose}>
            <FormattedMessage id="closeButtonSettingsModalText" />
          </Button>
          <Button variant="primary" onClick={handleSave}>
            <FormattedMessage id="saveButtonSettingsModalText" />
          </Button>
        </Modal.Footer>
      </Modal>
    </div>
  );
};

export default SettingsModal;
