import { Link } from 'react-router-dom';
import { Collapse, Form, Row, Col } from 'react-bootstrap';
import { useState } from 'react';
import DOMPurify from 'dompurify';
import { FormattedMessage, useIntl } from 'react-intl';
import CustomQuestionsService from '../../services/CustomQuestionsService';
import QtiService from '../../utils/qti-converter';
import { useAppContext } from '../../context/AppContext';
import { METADATA_OPTIONS } from '../../config/metadata-constants';
import { EditorProvider, Editor, Toolbar } from '../lib/rte';

const Essay = props => {
  const { questionNode, questionNodeIndex } = props;
  const [open, setOpen] = useState(false);
  const intl = useIntl();

  const initFormData = {
    question: questionNode.qtiModel ? questionNode.qtiModel.Caption : '',
    answer: questionNode.qtiModel ? questionNode.qtiModel.RecommendedAnswer : '',
    essayQuestionSize: questionNode.qtiModel ? questionNode.qtiModel.EssayPageSize : '',
    Caption: questionNode.qtiModel ? questionNode.qtiModel.Caption : '',
    Options: questionNode.qtiModel ? questionNode.qtiModel.Options : ['', '', '', ''],
    CorrectAnswer: questionNode.qtiModel ? questionNode.qtiModel.CorrectAnswer : -1,
    Orientation: questionNode.qtiModel ? 'true' : 'false',
    questionId: questionNode ? questionNode?.extendedMetadata?.questionId : '',
    pageReference: questionNode ? questionNode?.extendedMetadata?.pageReference : '',
    topic: questionNode ? questionNode?.extendedMetadata?.topic : '',
    skill: questionNode ? questionNode?.extendedMetadata?.skill : '',
    objective: questionNode ? questionNode?.extendedMetadata?.objective : '',
    difficulty: questionNode ? questionNode?.extendedMetadata?.difficulty : '',
  };

  if (Array.isArray(questionNode.extendedMetadata)) {
    questionNode.extendedMetadata.forEach(metadata => {
      initFormData[metadata.name] = metadata.value;
    });
  }
  const [formData, setFormData] = useState(initFormData);
  const { userMetadata } = useAppContext();
  const [options, setOptions] = useState('');

  const handleChange = e => {
    const { name, value } = e.target || e;
    setFormData({ ...formData, [name]: value });
  };

  const handleDropdownChange = e => {
    const { name, value } = e.target;
    setFormData(prev => ({ ...prev, [name]: value }));
  };
  const handleOptionChange = e => {
    setOptions(e.target.value);
  };
  const handleSubmit = e => {
    e.preventDefault();
    if (questionNode) {
      questionNode.qtiModel.Caption = formData.question;
      questionNode.qtiModel.RecommendedAnswer = DOMPurify.sanitize(formData.answer);
      questionNode.qtiModel.EssayPageSize = formData.essayQuestionSize;
      questionNode.qtiModel.EditOption = false;
      questionNode.extendedMetadata = {
        questionId: formData.questionId,
        pageReference: formData.pageReference,
        topic: formData.topic,
        skill: formData.skill,
        objective: formData.objective,
        difficulty: formData.difficulty,
      };
      let jsonToXML = QtiService.getQtiXML(questionNode);
      questionNode.data = jsonToXML;
      const questionTemplates = CustomQuestionsService.questionTemplates(questionNode);

      let xmlToHtml = questionTemplates[0].textHTML;

      const testObj = { ...props.selectedTest }; // Create a copy of selectedTest

      // Find if any question in the array has the same itemId
      const existingQuestion = testObj.questions.find(q => q.itemId === questionNode.itemId);

      if (!existingQuestion) {
        // If the question doesn't exist, add it to the end of the array
        questionNode.spaceLine = formData.spaceLine || 0;
        questionNode.textHTML = xmlToHtml;
      } else {
        // If the question already exists, update it
        testObj.questions[props.questionNodeIndex] = {
          ...testObj.questions[props.questionNodeIndex],
          spaceLine: formData.spaceLine || 0,
          textHTML: xmlToHtml,
        };
      }

      // Update the selected test with the modified questions array
      props.setSelectedTest(testObj);
    }
    props.onQuestionStateChange(false);
  };

  const handleEdit = e => {
    e.preventDefault();
    questionNode.qtiModel.EditOption = true;
    questionNode.qtiModel.EditAttempted = true;
    props.onQuestionStateChange(true);
  };

  const handleDelete = e => {
    e.preventDefault();
    if (questionNode.qtiModel.EditOption) {
      questionNode.qtiModel.EditOption = false;
      props.onQuestionDelete(questionNodeIndex);
      props.onQuestionStateChange(false);
    } else {
      props.onQuestionDelete(questionNodeIndex);
      props.onQuestionStateChange(false);
    }
  };

  const handleEssayQuestionSizeChange = value => {
    let essayQuestionSize = 'essayQuestionSize';
    setFormData({ ...formData, [essayQuestionSize]: value });
  };

  const sanitizedData = data => ({
    __html: DOMPurify.sanitize(data),
  });

  const handleKeyDown = event => {
    if (event.key === 'Enter' || event.key === ' ') {
      event.preventDefault();
      setOpen(!open);
    }
  };

  const getEditView = () => {
    const isViewButtonEnabled = formData.question.trim() !== '';

    return (
      <div className="m-2 border rounded p-3 bg-light">
        <EditorProvider>
          <Toolbar />
          <Form onSubmit={handleSubmit} className="editmode paddingright10">
            <Form.Group className="mb-3" controlId="exampleForm.ControlInput1">
              <Form.Label className="mb-1">
                <b>{questionNode.qtiModel.QstnSectionTitle}</b>
              </Form.Label>

              <Editor
                className="mb-2"
                placeholder={questionNode.qtiModel.EditCaption}
                value={formData.question}
                onChange={value => handleChange({ name: 'question', value })}
              />

              {/* <Form.Control type="text" name="question" value={formData.question} onChange={handleChange} /> */}
            </Form.Group>
            <Form.Group className="mb-3" controlId="exampleForm.ControlTextarea1">
              <Form.Label className="mb-1">
                <b>{questionNode.qtiModel.EditRecommendedAnswer}</b>
              </Form.Label>
              <Form.Control as="textarea" rows={3} name="answer" value={formData.answer} onChange={handleChange} />
            </Form.Group>
            <div className="d-flex align-items-center mb-3">
              <div className="pointer" onClick={() => setOpen(!open)} onKeyDown={handleKeyDown} tabIndex="0">
                {open ? <i className="bi bi-caret-down-fill"></i> : <i className="bi bi-caret-right-fill"></i>}
              </div>
              <span className="ms-2" tabIndex="0">
                <b>
                  <FormattedMessage id="message.formatAndAddMetadata" defaultMessage="Format and Add Metadata" />
                </b>
              </span>
            </div>

            <Collapse key={open ? 'open' : 'closed'} in={open}>
              <div className="metadata-container">
                <div className="mb-2">
                  <span className="caption-metadata">
                    <FormattedMessage id="formatTrueFalse" />
                  </span>
                  <span className="normal-text">
                    <FormattedMessage id="visibleInPrintViewTrueFalse" />
                  </span>
                </div>

                <div className="d-flex gap-2 pl-4">
                  <label htmlFor="option1">
                    <FormattedMessage id="EssaySpaceLabel" defaultMessage="Essay Space:" />
                  </label>
                  <input
                    type="radio"
                    id="option1"
                    name="options"
                    value="0"
                    checked={formData.essayQuestionSize === '0'}
                    onChange={() => handleEssayQuestionSizeChange('0')}
                  />
                  <label htmlFor="option1" tabIndex="0">
                    <FormattedMessage id="none" defaultMessage="None" />
                  </label>
                  <input
                    type="radio"
                    id="option2"
                    name="options"
                    value="10"
                    checked={formData.essayQuestionSize === '10'}
                    onChange={() => handleEssayQuestionSizeChange('10')}
                  />
                  <label htmlFor="option2" tabIndex="0">
                    <FormattedMessage id="quarterPage" defaultMessage="1/4 Page" />
                  </label>
                  <input
                    type="radio"
                    id="option3"
                    name="options"
                    value="20"
                    checked={formData.essayQuestionSize === '20'}
                    onChange={() => handleEssayQuestionSizeChange('20')}
                  />
                  <label htmlFor="option3" tabIndex="0">
                    <FormattedMessage id="halfPage" defaultMessage="1/2 Page" />
                  </label>
                  <input
                    type="radio"
                    id="option4"
                    name="options"
                    value="40"
                    checked={formData.essayQuestionSize === '40'}
                    onChange={() => handleEssayQuestionSizeChange('40')}
                  />
                  <label htmlFor="option4" tabIndex="0">
                    <FormattedMessage id="fullPage" defaultMessage="1 Page" />
                  </label>
                </div>

                <div className="mt-3 metadata-fields-container">
                  <FormattedMessage id="message.metadataTitle" />
                  <Row className="m-2">
                    <Col xs={12}>
                      {userMetadata.includes(METADATA_OPTIONS.QUESTION_ID) && (
                        <Form.Group controlId="formGridID" className="mt-2 d-flex align-items-center">
                          <Form.Label>
                            <FormattedMessage id="questionIdLabel" />
                          </Form.Label>
                          <Form.Control
                            aria-label="Question ID"
                            type="text"
                            placeholder=""
                            className=""
                            name={METADATA_OPTIONS.QUESTION_ID}
                            value={formData.questionId}
                            onChange={handleChange}
                          />
                        </Form.Group>
                      )}
                      {userMetadata.includes(METADATA_OPTIONS.DIFFICULTY) && (
                        <Form.Group className="mt-2 d-flex align-items-center">
                          <Form.Label>
                            <FormattedMessage id="difficultyLabel" />
                          </Form.Label>
                          <Form.Select
                            aria-label="difficultyLabel"
                            className=""
                            size="md"
                            name={METADATA_OPTIONS.DIFFICULTY}
                            value={formData.difficulty}
                            onChange={handleDropdownChange}
                          >
                            <option value="" className="difficultyLabelOptional">
                              <FormattedMessage id="chooseLevel" />
                            </option>
                            <option value="Easy" className="difficultyLabelOptional">
                              <FormattedMessage id="difficultyEasy" />
                            </option>
                            <option value="Moderate" className="difficultyLabelOptional">
                              <FormattedMessage id="difficultyMedium" />
                            </option>
                            <option value="Difficult" className="difficultyLabelOptional">
                              <FormattedMessage id="difficultyHard" />
                            </option>
                          </Form.Select>
                        </Form.Group>
                      )}
                      {userMetadata.includes(METADATA_OPTIONS.PAGE_REFERENCE) && (
                        <Form.Group className="mt-2 d-flex align-items-center">
                          <Form.Label>
                            <FormattedMessage id="pageReferenceLabel" />
                          </Form.Label>
                          <Form.Control
                            aria-label="pageReferenceLabel"
                            type="text"
                            placeholder=""
                            className=""
                            name={METADATA_OPTIONS.PAGE_REFERENCE}
                            value={formData.pageReference}
                            onChange={handleChange}
                          />
                        </Form.Group>
                      )}
                      {userMetadata.includes(METADATA_OPTIONS.TOPIC) && (
                        <Form.Group className="mt-2 d-flex align-items-center">
                          <Form.Label>
                            <FormattedMessage id="topicLabel" />
                          </Form.Label>
                          <Form.Control
                            aria-label="topicLabel"
                            type="text"
                            placeholder=""
                            className=""
                            name={METADATA_OPTIONS.TOPIC}
                            value={formData.topic}
                            onChange={handleChange}
                          />
                        </Form.Group>
                      )}
                      {userMetadata.includes(METADATA_OPTIONS.SKILL) && (
                        <Form.Group className="mt-2 d-flex align-items-center">
                          <Form.Label>
                            <FormattedMessage id="skillLabel" />
                          </Form.Label>
                          <Form.Control
                            aria-label="skillLabel"
                            type="text"
                            placeholder=""
                            className=""
                            name={METADATA_OPTIONS.SKILL}
                            value={formData.skill}
                            onChange={handleChange}
                          />
                        </Form.Group>
                      )}
                      {userMetadata.includes(METADATA_OPTIONS.OBJECTIVE) && (
                        <Form.Group className="mt-2 d-flex align-items-center">
                          <Form.Label>
                            <FormattedMessage id="objectiveLabel" />
                          </Form.Label>
                          <Form.Control
                            aria-label="objectiveLabel"
                            type="text"
                            placeholder=""
                            className=""
                            name={METADATA_OPTIONS.OBJECTIVE}
                            value={formData.objective}
                            onChange={handleChange}
                          />
                        </Form.Group>
                      )}
                    </Col>
                  </Row>
                </div>
              </div>
            </Collapse>
          </Form>
          <div className="mb-1 d-flex justify-content-end">
            <div
              className="tooltip-wrapper"
              title={intl.formatMessage({ id: 'viewButtonTitle', defaultMessage: 'View' })}
              tabIndex="0"
            >
              <button
                className={`savelink ${!isViewButtonEnabled ? 'disabled-link' : ''}`}
                onClick={handleSubmit}
                tabIndex={!isViewButtonEnabled ? -1 : 0}
                disabled={!isViewButtonEnabled}
                aria-label="view"
              >
                <i className="bi bi-eye"></i>
              </button>
            </div>
            <button
              className="deletelink"
              onClick={handleDelete}
              title={intl.formatMessage({ id: 'deleteButtonTitle', defaultMessage: 'Remove' })}
            >
              <i className="bi bi-trash"></i>
            </button>
          </div>
        </EditorProvider>
      </div>
    );
  };

  const getPrintOnlyView = () => {
    return (
      <div className="mb-1 d-flex align-items-center m-2 question-container">
        <div className="flex-grow-1 d-flex ml-7 d-flex">
          <div className="mr-2 pl-1 d-flex align-items-start gap-1">
            {questionNodeIndex + 1})
            <span
              className="view-content flex-grow-1"
              dangerouslySetInnerHTML={sanitizedData(formData.question)}
            ></span>
          </div>
        </div>
      </div>
    );
  };

  const getPrintWithEditView = () => {
    return (
      <div className="mb-1 d-flex align-items-center m-2 question-container">
        <div className="flex-grow-1 d-flex ml-7 d-flex">
          <div className="mr-2 pl-1 d-flex align-items-start gap-1">
            {questionNodeIndex + 1})
            <span
              className="view-content flex-grow-1"
              dangerouslySetInnerHTML={sanitizedData(formData.question)}
            ></span>
          </div>
        </div>
        <div className="mr-7 d-flex align-items-center d-flex justify-content-end align-self-start">
          <button
            className="editbtn"
            onClick={handleEdit}
            title={intl.formatMessage({ id: 'editButtonTitle', defaultMessage: 'Edit' })}
          >
            <i className="bi bi-pencil-fill"></i>
          </button>
          <button
            className="deletebtn"
            onClick={handleDelete}
            title={intl.formatMessage({ id: 'deleteButtonTitle', defaultMessage: 'Remove' })}
          >
            <i className="bi bi-trash"></i>
          </button>
        </div>
      </div>
    );
  };

  const getPrintWithAnswerView = () => {
    return (
      <div className="mb-1 d-flex align-items-center m-2 question-container">
        <div className="flex-grow-1 d-flex ml-7 d-flex">
          <div className="mr-2 pl-1 d-flex align-items-start gap-1">
            {questionNodeIndex + 1})
            <span
              className="view-content flex-grow-1"
              dangerouslySetInnerHTML={sanitizedData(formData.question)}
            ></span>
          </div>
        </div>
      </div>
    );
  };

  const getPrintView = viewId => {
    if (viewId == 3) {
      return getPrintWithAnswerView();
    } else if (viewId == 2) {
      return getPrintWithEditView();
    } else {
      return getPrintOnlyView();
    }
  };

  return (
    <div id={questionNode.itemId}>
      {!questionNode.qtiModel.EditOption ? getPrintView(props.printView) : getEditView()}
    </div>
  );
};
export default Essay;
