import React, { useState, useEffect } from 'react';
import { useIntl } from 'react-intl';
import { toastify } from '../../common/Toastify';
import { saveTestFolder, updateTestFolder } from '../../../services/testfolder.service';
import { getRootTests } from '../../../services/testcreate.service';
import FoldersTreeView from './FoldersTreeView';
import AddFolder from '../../AddFolder';
import './FoldersList.css';

const FoldersList = ({ rootFolders, fetchUserFolders, rootFolderGuid }) => {
  const [updateKey, setUpdateKey] = useState(0);
  const [savedFolders, setSavedFolders] = useState([]);
  const intl = useIntl();

  useEffect(() => {
    const savedFoldersFromStorage = JSON.parse(localStorage.getItem('savedFolders'));
    if (savedFoldersFromStorage) {
      setSavedFolders(savedFoldersFromStorage);
    }
  }, []);

  // need to update this
  const handleSaveFolder = async folderName => {
    const trimmedFolderName = folderName.trim();
    if (!trimmedFolderName) {
      toastify.showErrorToast(intl.formatMessage({ id: 'error.FolderNameCannotBeEmpty' }));
      return;
    }
    if (folderName.trim() !== '') {
      try {
        const maxSequence = savedFolders.reduce((max, folder) => {
          return folder.sequence > max ? folder.sequence : max;
        }, 1);
        const newSequence = maxSequence + 1;

        const newFolderData = {
          parentId: rootFolderGuid,
          sequence: newSequence,
          title: folderName,
        };
        const savedFolder = await saveTestFolder(
          newFolderData
          // userId
        );
        const updatedFolders = [...savedFolders, savedFolder];
        setSavedFolders(updatedFolders);
        localStorage.setItem('savedFolders', JSON.stringify(updatedFolders));
        setUpdateKey(updateKey + 1);

        toastify.showSuccessToast(intl.formatMessage({ id: 'success.Folder.savedsuccessfully' }));

        // Fetch the updated folders immediately after saving or updating
        fetchUserFolders();
      } catch (error) {
        console.error('Error saving folder:', error);
        if (error?.message?.response?.request?.status === 409) {
          toastify.showErrorToast(error.message.response.data.message);
        } else {
          toastify.showErrorToast(intl.formatMessage({ id: 'error.Failed.to.savefolder' }));
        }
      }
    }
    console.log('now', rootFolderGuid);
  };

  const onNodeUpdate = async changedNode => {
    try {
      await updateTestFolder(changedNode);

      toastify.showSuccessToast(intl.formatMessage({ id: 'success.Folder.rearranged.successfully' }));
    } catch (error) {
      console.error('Error rearranging folder:', error);
      if (error?.message?.response?.request?.status === 409) {
        toastify.showErrorToast(error.message.response.data.message);
      } else {
        toastify.showErrorToast(intl.formatMessage({ id: 'error.Failed.to.rearrangefolder' }));
      }
    }
  };

  return (
    <>
      <AddFolder onFolderAdd={handleSaveFolder} />
      <div className="root-folders-tests">
        {rootFolders && rootFolders.length > 0 && (
          <FoldersTreeView
            key={updateKey}
            folders={rootFolders}
            onNodeUpdate={onNodeUpdate}
            rootFolderGuid={rootFolderGuid}
          />
        )}
      </div>
    </>
  );
};

export default FoldersList;
