import React, { useState } from 'react';
import { Col, Collapse, Form, Row } from 'react-bootstrap';
import { FormattedMessage } from 'react-intl';
import { METADATA_OPTIONS } from '../../../config/metadata-constants';
import { useAppContext } from '../../../context/AppContext';

const FormatAndAddExtendedMetadataSection = ({ data, setData }) => {
  const { userMetadata } = useAppContext();
  const [expanded, setExpanded] = useState(false);

  const handleChange = e => {
    const { name, value } = e.target || e;

    setData({ ...data, [name]: value });
  };

  const handleDropdownChange = e => {
    const { name, value } = e.target;
    setData(prev => ({ ...prev, [name]: value }));
  };

  return (
    <>
      <div className="d-flex align-items-center mb-3">
        <div className="pointer" onClick={() => setExpanded(!expanded)}>
          {expanded ? <i className="bi bi-caret-down-fill"></i> : <i className="bi bi-caret-right-fill"></i>}
        </div>
        <span className="ms-2" tabIndex="0">
          <b>
            <FormattedMessage id="message.formatAndAddMetadata" />
          </b>
        </span>
      </div>
      <Collapse key={expanded ? 'open' : 'closed'} in={expanded}>
        <div className="metadata-container">
          {data.Orientation !== undefined && (
            <>
              <div className="mb-2">
                <span className="caption-metadata">
                  <FormattedMessage id="formatMultipleResponse" />
                </span>
                <span className="normal-text">
                  <FormattedMessage id="visibleInPrintViewMultipleResponse" />
                </span>
              </div>
              <div className="d-flex mc--orientation" tabIndex="0">
                <Form.Check
                  type="radio"
                  onChange={handleChange}
                  checked={'false' === data.Orientation}
                  className="mr-5"
                  name="Orientation"
                  value="false"
                  label="Horizontal Displays"
                  tabIndex="0"
                />

                <Form.Check
                  type="radio"
                  onChange={handleChange}
                  checked={'true' === data.Orientation}
                  className="mr-5"
                  name="Orientation"
                  value="true"
                  label="Vertical Display"
                  tabIndex="0"
                />
              </div>
            </>
          )}
          <div className="mt-3 metadata-fields-container">
            <FormattedMessage id="message.metadataTitle" />
            <Row className="m-2">
              <Col xs={12}>
                {userMetadata.includes(METADATA_OPTIONS.QUESTION_ID) && (
                  <Form.Group controlId="formGridID" className="mt-2 d-flex align-items-center">
                    <Form.Label>
                      <FormattedMessage id="questionIdLabel" />
                    </Form.Label>
                    <Form.Control
                      aria-label="Question ID"
                      type="text"
                      placeholder=""
                      className=""
                      name={METADATA_OPTIONS.QUESTION_ID}
                      value={data.questionId}
                      onChange={handleChange}
                    />
                  </Form.Group>
                )}
                {userMetadata.includes(METADATA_OPTIONS.DIFFICULTY) && (
                  <Form.Group className="mt-2 d-flex align-items-center">
                    <Form.Label>
                      <FormattedMessage id="difficultyLabel" />
                    </Form.Label>
                    <Form.Select
                      aria-label="difficultyLabel "
                      className=""
                      size="md"
                      name={METADATA_OPTIONS.DIFFICULTY}
                      value={data.difficulty}
                      onChange={handleDropdownChange}
                    >
                      <option value="" className="difficultyLabelOptional">
                        <FormattedMessage id="chooseLevel" />
                      </option>
                      <option value="Easy" className="difficultyLabelOptional">
                        <FormattedMessage id="difficultyEasy" />
                      </option>
                      <option value="Moderate" className="difficultyLabelOptional">
                        <FormattedMessage id="difficultyMedium" />
                      </option>
                      <option value="Difficult" className="difficultyLabelOptional">
                        <FormattedMessage id="difficultyHard" />
                      </option>
                    </Form.Select>
                  </Form.Group>
                )}
                {userMetadata.includes(METADATA_OPTIONS.PAGE_REFERENCE) && (
                  <Form.Group className="mt-2 d-flex align-items-center">
                    <Form.Label>
                      <FormattedMessage id="pageReferenceLabel" />
                    </Form.Label>
                    <Form.Control
                      aria-label="pageReferenceLabel"
                      type="text"
                      placeholder=""
                      className=""
                      name={METADATA_OPTIONS.PAGE_REFERENCE}
                      value={data.pageReference}
                      onChange={handleChange}
                    />
                  </Form.Group>
                )}
                {userMetadata.includes(METADATA_OPTIONS.TOPIC) && (
                  <Form.Group className="mt-2 d-flex align-items-center">
                    <Form.Label>
                      <FormattedMessage id="topicLabel" />
                    </Form.Label>
                    <Form.Control
                      aria-label="topicLabel"
                      type="text"
                      placeholder=""
                      className=""
                      name={METADATA_OPTIONS.TOPIC}
                      value={data.topic}
                      onChange={handleChange}
                    />
                  </Form.Group>
                )}
                {userMetadata.includes(METADATA_OPTIONS.SKILL) && (
                  <Form.Group className="mt-2 d-flex align-items-center">
                    <Form.Label>
                      <FormattedMessage id="skillLabel" />
                    </Form.Label>
                    <Form.Control
                      aria-label="skillLabel"
                      type="text"
                      placeholder=""
                      className=""
                      name={METADATA_OPTIONS.SKILL}
                      value={data.skill}
                      onChange={handleChange}
                    />
                  </Form.Group>
                )}
                {userMetadata.includes(METADATA_OPTIONS.OBJECTIVE) && (
                  <Form.Group className="mt-2 d-flex align-items-center">
                    <Form.Label>
                      <FormattedMessage id="objectiveLabel" />
                    </Form.Label>
                    <Form.Control
                      aria-label="objectiveLabel"
                      type="text"
                      placeholder=""
                      className=""
                      name={METADATA_OPTIONS.OBJECTIVE}
                      value={data.objective}
                      onChange={handleChange}
                    />
                  </Form.Group>
                )}
              </Col>
            </Row>
          </div>
        </div>
      </Collapse>
    </>
  );
};

export default FormatAndAddExtendedMetadataSection;
