import React, { useMemo } from 'react';
import { FormattedMessage } from 'react-intl';
import CustomQuestionsService from '../../../services/CustomQuestionsService';
import QtiService from '../../../utils/qti-converter';
import './PrintTestTreeView.css';

/**
 * Returns a shuffled copy of the input options array.
 *
 * @param {Object[]} options - The input options array.
 *
 * @returns {Object[]} A shuffled copy of the input options array.
 */
const getShuffledOptions = options => {
  /**
   * Create a deep copy of the input options array to avoid modifying the original array.
   */
  const shuffledOptions = [...options].map(option => ({ ...option }));

  /**
   * Implement the Fisher-Yates shuffle algorithm to randomize the order of the options.
   */
  for (let i = shuffledOptions.length - 1; i > 0; i--) {
    const j = Math.floor(Math.random() * (i + 1));
    [shuffledOptions[i].matchingOption, shuffledOptions[j].matchingOption] = [
      shuffledOptions[j].matchingOption,
      shuffledOptions[i].matchingOption,
    ];
  }

  return shuffledOptions;
};

/**
 * Gets the HTML with shuffled options for the given question.
 *
 * @param {Object} question - The question object.
 * @returns {string} The HTML with shuffled options.
 */
const getHTMLWithShuffledOptions = question => {
  question.qtiModel.Options = getShuffledOptions(question.qtiModel.Options);

  const jsonToXML = QtiService.getQtiXML(question);
  question.data = jsonToXML;

  const questionTemplates = CustomQuestionsService.questionTemplates(question);
  return questionTemplates[0].textHTML;
};

const useGenerateHTML = item => {
  const matchingHTML = useMemo(() => {
    return item.quizType === 'Matching' ? getHTMLWithShuffledOptions(item) : item.textHTML;
  }, [item.qtiModel?.Options?.length]);

  return matchingHTML;
};

const PrintTestTreeItem = ({ item }) => {
  const html = useGenerateHTML(item);
  return (
    <div
      className={`printViewContainer ${item.quizType === CustomQuestionsService.FillInBlanks ? 'line-height-2' : ''}`}
      dangerouslySetInnerHTML={{
        __html: html,
      }}
      id="print-popup-container"
    />
  );
};

const PrintTestTreeView = React.forwardRef(({ savedQuestions, addStudentName, isleftSideMargin }, ref) => {
  return (
    <div ref={ref}>
      <div className={`p-3`}>
        {addStudentName && (
          <div className="student-name">
            <span>
              <FormattedMessage id="studentNamePrintTestTreeView" />
            </span>
          </div>
        )}
        {savedQuestions.map((item, index) => {
          return (
            <div key={index} className={`fs-6 question-block ${isleftSideMargin ? 'left-side-margin' : ''}`}>
              <div className="d-flex gap-2">
                <span className="question-number">{index + 1})</span>
                <div style={{ width: '100%' }}>
                  <PrintTestTreeItem item={item} />
                </div>
              </div>
              {Array.from({ length: item.spaceLine }, (_, spaceIndex) => (
                <div key={spaceIndex} className="w-100 p-3 mx-2"></div>
              ))}
            </div>
          );
        })}
      </div>
    </div>
  );
});

export default PrintTestTreeView;
