import { Routes, Route, Navigate } from 'react-router-dom';
import HomePage from '../pages/home-page/HomePage.js';
import StartPage from '../pages/start-page/StartPage.js';
import TestsPage from '../pages/TestsPage.js';
import YourQuestionsPage from '../pages/YourQuestionsPage.js';
import NoPage from '../pages/NoPage.js';
import FullLayout from '../pages/layouts/FullLayout.js';
import CustomQuestionsPage from '../pages/CustomQuestionsPage.js';
import QuestionTemplatesPage from '../pages/question-templates-page/QuestionTemplatesPage.js';
import QuestionBanks from '../pages/QuestionBanks/QuestionBanks.js';
import Discipline from '../pages/Discipline/Discipline.js';
import WelcomeScreen from '../pages/welcome-screen/WelcomeScreen.js';
import Booktab from '../pages/Booktab/Booktab.js';
import ProtectedRoutes from './ProtectedRoutes.js';

const AppRoutes = (
  <Routes>
    <Route element={<ProtectedRoutes />}>
      <Route path="/" element={<FullLayout />}>
        <Route path="/" element={<Navigate to="/home" />} />
        <Route path="/home" element={<HomePage />}>
          <Route path="/home" element={<Navigate to="/home/tests" />} />
          <Route path="/home/tests" element={<TestsPage />} />
          <Route path="/home/questions" element={<YourQuestionsPage />} />
          <Route path="/home/questionbanks" element={<QuestionBanks />} />
          <Route path="/home/questiontemplate" element={<CustomQuestionsPage />} />
          <Route path="/home/questiontemplates" element={<QuestionTemplatesPage />} />
        </Route>
        <Route path="*" element={<NoPage />} />
      </Route>
      <Route path="/welcomescreen" element={<WelcomeScreen />} />
      <Route path="/discipline" element={<Discipline />} />
      <Route path="/booktab" element={<Booktab />} />
      <Route path="/start" element={<StartPage />} />
    </Route>
  </Routes>
);

export default AppRoutes;
