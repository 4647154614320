import React, { useEffect, useState } from 'react';
import { FormattedMessage, useIntl } from 'react-intl';
import { useAppContext } from '../../context/AppContext';
import BooksTreeView from './BooksTreeView';
import { toastify } from '../common/Toastify';
import SearchBox from '../common/SearchBox/SearchBox';
import { getDisciplineBooks, saveUserBooks } from '../../services/book.service';
import { saveUserDiscipline } from '../../services/discipline.service';
import './Bookspopup.css';

const AddBookspopup = ({ handleBack, handleSave }) => {
  const intl = useIntl();
  const [searchTerm, setSearchTerm] = useState('');
  const [treeData, setTreeData] = useState([]);

  const {
    disciplinesData: { selectedDisciplines, selectedBooks },
    dispatchEvent,
  } = useAppContext();

  useEffect(() => {
    document.title = 'Choose Your Books or Topics';
  }, []);

  useEffect(() => {
    fetchData();
  }, [selectedDisciplines]);

  /**
   * Fetches data for the selected disciplines and updates the tree data.
   * @param {string[]} selectedDisciplines - The selected disciplines to fetch data for.
   */
  const fetchData = async () => {
    dispatchEvent('SHOW_LOADER');

    try {
      if (selectedDisciplines) {
        let result = []; // Initialize an empty array to store the result
        const availableBooks = []; // Initialize an empty array to store available books

        // Loop through each selected discipline
        for (const discipline of selectedDisciplines) {
          const data = await getDisciplineBooks(discipline);

          // Filter and map the data to the desired format
          const nodes = data
            .filter(node => discipline === node.discipline)
            .map(node => {
              availableBooks.push(node.guid);
              return {
                id: node.guid,
                text: node.title,
                droppable: false,
                parentId: result.length,
                discipline,
              };
            });

          // Add the discipline to the result array
          result.push({
            id: result.length,
            text: discipline,
            droppable: true,
            nodes,
          });
        }

        // Filter the selectedBooks array to only include books that are available
        const selection = selectedBooks.filter(book => availableBooks.includes(book));
        dispatchEvent('UPDATE_DISCIPLINES_DATA', { selectedBooks: selection });

        setTreeData(result); // Set the tree data to the result array
      }
    } catch (error) {
      console.error('Error fetching data:', error);
      toastify.showWarningToast(error);
    } finally {
      dispatchEvent('HIDE_LOADER');
    }
  };

  /**
   * Saves the selected books and disciplines for the current user and displays a success toast message.
   * @returns {Promise} A promise that resolves when the books and disciplines have been saved and the toast message has been displayed.
   */
  const handleNext = async () => {
    try {
      await saveUserBooks(selectedBooks);
      await saveUserDiscipline(selectedDisciplines);
      handleSave();
      toastify.showSuccessToast(intl.formatMessage({ id: 'booksAndDiscipline' }));
    } catch (error) {
      toastify.showErrorToast(error);
    }
  };

  /**
   * Handles the search functionality by setting the search term.
   * @param {string} searchValue - The search term entered by the user.
   */
  const handleSearch = searchValue => {
    setSearchTerm(searchValue);
  };

  const handleSelectItem = node => {
    if (!node.droppable) {
      const isSelected = selectedBooks.includes(node.id);

      // Create a new array with the updated selection
      const updatedSelection = isSelected ? selectedBooks.filter(id => id !== node.id) : [...selectedBooks, node.id];

      // Update selectedBooks state with updated selection
      dispatchEvent('UPDATE_DISCIPLINES_DATA', { selectedBooks: updatedSelection });
    }
  };

  return (
    <div className="booktab-container">
      <div className="top-containerbooks">
        <h2 className="choose-your-books-or-topics">
          <FormattedMessage id="addBooks" defaultMessage="Add Books" />
        </h2>
        <button className="booktab btn btn-secondary" onClick={handleBack}>
          <FormattedMessage id="backButton" defaultMessage="Back" />
        </button>
        <button className="booktab btn btn-primary" disabled={selectedBooks?.length === 0} onClick={handleNext}>
          <FormattedMessage id="saveButton" defaultMessage="Save" />
        </button>
      </div>

      <SearchBox placeholder={intl.formatMessage({ id: 'message.searchBook' })} onSearch={handleSearch} tabIndex="0"/>

      <ul className="booktabaddpopup result-list mt-3" tabIndex="0">
        <BooksTreeView
          tabIndex="0"
          selectedItems={selectedBooks || []}
          onSelectItem={handleSelectItem}
          searchTerm={searchTerm}
          treeData={treeData}
        />
      </ul>
    </div>
  );
};

export default AddBookspopup;
