import Test from '../entities/Test.Entity';
import { getTestQuestions } from '../services/testcreate.service';
import { getQuestionFromDto } from './questions-utils';

/**
 * Formats a test title by removing disallowed characters and ensuring it is not longer than 255 characters.
 * Also capitalizes the first letter of the title.
 *
 * @param {string} title - The title to be formatted.
 * @return {string} The formatted title.
 */
export const sanitizeTitle = title => {
  // Remove disallowed characters and ensure title is not longer than 255 characters
  const disallowedCharacters = /[<>:"/\\|?]/;
  title = title.replace(disallowedCharacters, '').slice(0, 255);

  // Capitalize the first letter of the title
  title = title.charAt(0).toUpperCase() + title.slice(1);

  return title;
};

/**
 * Constructs a Test object from a given node.
 *
 * @param {Object} node - The node object to construct the test from.
 * @returns {Test} - The constructed Test object.
 */
export const constructTest = async node => {
  const testId = node.guid || node.id;
  const title = node.title || node.text;

  // Create a new Test object
  const test = new Test();

  // Set the test's title and tab title
  test.title = title;
  test.tabTitle = title;

  // Set the test's folder GUID, or null if the node has no parent
  test.folderGuid = node.parent === 0 ? null : node.parent;

  // Set the test's ID and migration status
  test.testId = testId;
  test.ismigrated = node.ismigrated;

  // Initialize the test's metadata object
  test.metadata = {};

  // Get the questions associated with the test
  const questions = await getTestQuestions(testId);

  // Convert each question DTO to a Question object and add to the test's questions array
  test.questions = questions.map(question => getQuestionFromDto(question));

  // Return the constructed Test object
  return test;
};

export const isTestReadyToSave = test => {
  return test && isAllQuestionsAreInViewMode(test.questions);
};

export const isAllQuestionsAreInViewMode = questions => {
  return (
    questions.length > 0 &&
    !questions.find(item => item.qtiModel?.EditOption || (item.data && item.data.qtiModel?.EditOption))
  );
};
